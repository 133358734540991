.transaction-details-table {
    padding: 50px 0;
}
.transaction-details-table .container {
    max-width: 700px !important;
}
.transaction-details-table h2 {
 font-size: 26px;
 font-weight: 600;
 margin-bottom: 15px;
}
.transaction-details-table table th {
    font-weight: 600;
}

.transaction-details-table table th, .transaction-details-table table td {
    font-size: 16px;
}