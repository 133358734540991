.studySelect_bg .left_section {
    background: #0F4D92 url('../../assets/images/studySelect.jpg') no-repeat 15px /contain;
    /* background-size: contain; */
    position: relative;
}
.right_section.studySelect_form{
    justify-content: flex-start;
    position: relative;
    left: -320px;
}
.session_box{
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 24px;
    background-color: #FAFAFA;
    border-radius: 8px;
    cursor: pointer;
}
.session_box:hover{
    background-color: #0F4D92;
}
.session_box:hover .session_box_inner h4{
    color: #ffffff;
}
.session_box:hover .session_box_inner p{
    color: #ffffff;
}
.session_box>img{
    min-width: 32px;
    width: 32px;
    height: 32px;
}
.session_box_inner h4{
    font-size: 24px;
    color: #000000;
    margin-bottom: 8px;
    text-transform: capitalize;
    font-weight: 600;
}
.session_box_inner p{
    font-size: 14px;
    color: #555555;
    text-transform: capitalize;
    font-weight: 600;
}
.session_selection .session_box:not(:last-child){
    margin-bottom: 32px;
}
.studySelect_form .login_form{
    min-width: 600px;
    max-width: 600px;
}
.session_selection .session_box:hover>img {
    filter: brightness(100);
}
.session_box:hover>svg>g>path:first-child {
    fill: #fff;
}
[dir=rtl] .right_section.studySelect_form{
    left: unset;
    right: -320px;
}
[dir=rtl] .studySelect_bg .left_section{
    transform: scaleX(-1);
}
.left_section>img:first-child {
    position: relative;
    z-index: 2;
}
[dir=rtl] .cloud_abs{
    right: -1px;
}
[dir=rtl] .mainLogo{
    position: absolute;
    left: 50px;
    top: 50px;
}
.session_box:hover>svg.privateSvg>g>path:first-child {
    fill: #eb0029;
}
.session_box:hover>svg.privateSvg>path{
    filter: brightness(100);
}
.left_section>img:first-child {
    position: relative;
    z-index: 2;
}
@media(max-width:1400px){
    [dir=rtl] .mainLogo{
        position: absolute;
        left: 15px;
        top: 15px;
    }
}

@media(max-width:1024px){
    .right_section.studySelect_form{
        left: 0 !important;
    }
    [dir=rtl] .right_section.studySelect_form{
        left: unset !important;
        right: 0 !important;
    }
    .studySelect_form .login_form{
        min-width: 100% !important;
        max-width: 100% !important;
    }
    .studySelect_bg .left_section{
        width: 100%;
    }
    .studySelect_bg .left_section {
        min-height: 100%;
        width: 100%;
        position: static;
    }
    .right_section.studySelect_form {
        margin-top: 70px;
    }
}
@media(max-width:576px){
    .studySelect_bg .left_section{
        min-height: 450px;
    }
    .right_section.studySelect_form{
        margin-top: 40px;
    }
}
