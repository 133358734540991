body {
  margin: 0;
  font-family: '-apple-system', 'BlinkMacSystemFont', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .mjx-chtml {
  font-family: 'Rajdhani', sans-serif !important;
}
[dir="rtl"] .mjx-chtml {
  font-family: 'Tajawal', sans-serif !important;
} */

code {
  font-family: 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', "Courier New",
    monospace;
}
#root {
  width: 100%;
  height: 100%;
  overflow: auto !important;
}
.cis-chat-username {
  font-size: 12px;
}
.cis-chat-other-username {
  font-size: 12px;
}
[dir="ltr"] .cis-chat-username {
text-align: right;
}
[dir="rtl"] .cis-chat-username {
  text-align: left;
}
.cis-chat-input {
  width: 100%;
  background: #f4f4f4;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
  margin: 0px;
}

/* The Modal (background) */
.error-modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.error-modal .error-modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  max-width: 400px;
  width: 70%;
}
.error-modal .error-modal-content .MuiSvgIcon-root {
  font-size: 60px;
}
.cis-modal-wrap {
  text-align: center;
  padding: 10px 0;
}

.cis-modal-wrap p {
  margin-bottom: 0;
  font-size: 20px;
}

.cis-modal-wrap a {
  margin-bottom: 0;
  font-size: 20px;
}

/* The Close Button */
.error-modal-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.error-modal-close:hover,
.error-modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
/* [dir="rtl"] .good-bad-rating>span.MuiRating-root {
 flex-direction: row-reverse;
} */


@media(max-width: 899px) {
  .container {
    max-width: 80%;
    margin: 0 auto;
  }
}

@media(max-width:767px){
  .container {
      max-width: 90%;
      padding: 0 5%;
  }
  .form-wrap {
      padding: 8% !important;
  }
  .form-wrap>.MuiBox-root {
      max-width: 100% !important;
  }
}

.cis-cadImg-wrap.design-circle-img img {border-radius: 50%;width: 80%;height: 80%;margin-top: 10%;margin-left: 10%;border: 1px solid #ededed;}

.cis-cadImg-wrap.design-circle-img img.cis-cadImg.squre-Img {}

.cis-cadImg-wrap.design-circle-img {background: transparent;border-style: none;box-shadow: none;}

.favorite-circle-card {
    box-shadow: none !important;
    border-style: none !important;
    text-align: center;
}

.cis-cadImg-wrap.design-circle-img .offlinestatus,
.cis-cadImg-wrap.design-circle-img .livestatus {
    top: 20px;
    right: 20px;
    width: 10px;
    height: 10px;
}

.cis-tutor-cards .favorite-circle-card .cardContent {
    padding-top: 0 !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  border-radius: 16px !important;
}
.text-danger {
  color: #ef5350;
  font-size: 14px;
  margin-top: 4px;
  display: block;
  width: 100%;
}
.cis-dialog-1 .MuiDialog-paper {
  padding: 35px 20px 45px 20px;
  width: 370px;
}
.cis-dialog-1 .MuiTypography-h6{
  font-size: 20px;
  color: #005DB8;
}
.cis-dialog-1 .timing-box_text-wrap>span{
  background-color: #005DB8;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
  width: 70px;
  height: 70px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  text-align: center;
  line-height: 1.2;
  font-weight: 600;
}
.cis-dialog-1 .timing-box>.ripple_{
  border-radius: 50%;
}
.cis-dialog-1 .css-fa55pl-MuiButtonBase-root-MuiButton-root {
    font-size: 20px;
    height: 36px;
    width: 120px;
}
.cis-dialog-1 .css-ypiqx9-MuiDialogContent-root{
  padding-top: 20px;
  padding-bottom: 2px;
}
.cis-dialog-1 .timing-box{
  margin: 0 auto 20px;
}
.cis-dialog-2{
  width: 500px;
}
.timing-box>.ripple_ {
  animation: growAndFade 2.4s infinite ease-out;
  background-color: dodgerblue;
  border-radius: 10%;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0.4);
  animation-delay: 1.8s;
}
.timing-box .timing-box-2 {
  animation-delay: 1.2s;
}
.timing-box .timing-box-1 {
  animation-delay: 0.6s;
}
.timing-box .timing-box-3 {
  animation-delay: 1.8s;
}
.timing-box {
  height: auto;
  position: relative;
  width: 140px;
  margin: 0 auto;
}
.timing-box_text-wrap {
  width: 140px;
  height: 140px;
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
/* .timing-box_text-wrap>span {
  background: #ebf5ff;
  width: 70px;
  height: 70px;
  color: #000000;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  border-radius: 8px;
  text-align: center;
  line-height: 1.2;
  font-weight: 600;
} */


@keyframes growAndFade {
  0% {
    opacity: 0.25;
    transform: scale(0.4);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}
.lt_bg_wrap{
  background-color: #EAF4FF;
  padding: 16px 12px;
}
.title-1 {
  font-size: 25px;
}
.feedback_dialog .title-info {
  color: #050000;
  margin-bottom: 12px;
  font-weight: 500;
}
.feedback_dialog .title-1 {
  margin-bottom: 24px !important;
  color: #000000;
  font-weight: 600;
}
.ratinbox .rating-menus {
  justify-content: start;
  gap: 10px;
  flex-wrap: wrap;
}
.ratinbox .rating-menus>.MuiChip-root {
  margin-bottom: 0;
  font-size: 16px;
}
.rating-menus>.MuiChip-root {
  margin: 0px
}
.rating-menus>.MuiChip-root {
  margin-bottom: 10px;
  border-radius: 4px;
}
.feedback_dialog .MuiDialogActions-root {
  justify-content: center;
  padding-bottom: 30px;
}
.feedback_dialog .MuiDialogActions-root:disabled {
  background-color: #F5F5F5;
  color: #D3D3D3;
}
.feedback_dialog .MuiDialogActions-root .MuiButtonBase-root {
  min-width: 136px;
  background-color: #005DB8;
  color: #fff;
}
.feedback_dialog .MuiDialogActions-root .MuiButtonBase-root:disabled {
  background-color: #F5F5F5;
  color: #D3D3D3;
}
.cis-selected {
  background-color: #005DB8 !important;
  color: #fff !important;
}
.rotateCrop {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
.rcDialog .css-ypiqx9-MuiDialogContent-root {
  padding: 0;
  display: flex;
  align-items: center;
}
.rcDialog .rotateCrop .btn-gray {
  background: rgba(0, 0, 0, 0.7) !important;
}
.rcDialog .css-ypy65y-MuiButtonBase-root-MuiButton-root>img {
  width: 18px;
  margin-right: 5px;

}
.rcDialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  overflow-y: unset;
  min-width: 300px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rcDialog .css-12dy2kt-MuiDialogActions-root {
  padding: 0;
  position: absolute;
  bottom: -50px;
  width: 100%;
  justify-content: center;
}
.rcDialog {
  padding: 0;
  min-width: 250px;
  min-height: 200px;
  max-height: 95vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clr-red {
  /* color: #d32f2f; */
  color: #EA4435
}
/* [dir="rtl"] .main_slider .swiper .swiper-wrapper{
	justify-content: flex-end;
} */
[dir="rtl"] .MuiLinearProgress-root .MuiLinearProgress-bar {
  transform: translateX(-50%) rotate(180deg) !important;
  transform-origin: right;
}
/* .report-message .MuiOutlinedInput-root .MuiOutlinedInput-input {
  height: 150px
} */
::-webkit-scrollbar-track
{
	border-radius: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/* background-color: rgba(0, 0, 0, 0.7); */
  background-color: #1565c0;
}
.toolbar-shadow {
  display: none;
}
@media (max-width: 1199px) {
  .title-1 {
    font-size: 22px;
  }
  .ratinbox .rating-menus>.MuiChip-root {
    padding: 2px 5px;
  }
}