.inputName{
    input{
        background-color: #F5F5F5;
        color: #D3D3D3;
        &::placeholder{
            text-transform: capitalize;
        }
    }
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
        border-color: #F5F5F5;
    }
}
.css-q8hpuo-MuiFormControl-root.inputName{
    margin-bottom: 24px;
}
.registration_wrapper{
    .tab-wrapper {
        max-width: 470px;
        .login_form{
            max-width: 100%;
            min-width: 100%;
        }
    }
    .tab-wrapper{
        .css-1gsv261{
            max-width: 100%;
            padding: 0 32px;
            position: absolute;
            width: 100%;
            top: auto;
            bottom: 30px;
            .MuiTabs-flexContainer{
                border-bottom: none;
            }
        }
    }
    .custom-panel-wrapper{
        position: relative;
    }
    /* .css-1e6y48t-MuiButtonBase-root-MuiButton-root.next_btn{ */
    .next_btn {
        height: 46px;
        width: 100%;
        padding: 10px;
        background-color: #0F4D92;
        border: 1px solid #0F4D92;
        color: #ffffff;
        border-radius: 8px;
        margin-top: 24px;
        font-size: 20px;
        font-weight: 600;
        text-transform: capitalize;
        &:hover{
            color: #0F4D92;
            background-color: #ffffff;
            border: 1px solid #0F4D92;
        }
    }
    .custom-panel-wrapper>div>.MuiBox-root{
        /* padding: 0; */
    }

    .cusbtnFlx{
        display: flex;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;
        >button.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
            height: 32px;
            min-width: 97px;
            background-color: #F5F5F5;
            color: #555555;
            border-radius: 30px;
            font-size: 16px;
            font-weight: 500;
            text-transform: capitalize;
            padding: 6px 24px;
            &.active{
                background-color: #0F4D92;
                color: #ffffff;
            }
        }
    }
    .personalRadio{
        flex-direction: row;
        gap: 16px;
        .css-j204z7-MuiFormControlLabel-root{
            margin: 0;
            display: flex;
            align-items: center;
            gap: 8px;
        }
        .css-wxwe5p-MuiButtonBase-root-MuiRadio-root{
            padding: 0;
        }
        .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label{
            text-transform: capitalize;
        }
    }
    .css-1nrlq1o-MuiFormControl-root{
        margin-top: 15px;
    }
    .cusbtnFlx>.MuiFormControl-root {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        flex-direction: row;
    }
    .cusbtnFlx .MuiFormControl-root .MuiButtonBase-root.MuiButton-root {
        background: #F5F5F5;
        padding: 6px 15px;
        border-radius: 30px;
        font-size: 16px;
        color: #555555;
        height: 32px;
        border: none;
    }
    .cusbtnFlx .MuiFormControl-root .MuiButtonBase-root.MuiButton-root:hover, .cusbtnFlx .MuiFormControl-root .MuiButtonBase-root.MuiButton-root.active {
        background: #0F4D92;
        color: #fff;  
    }
    .sub-exam {
        width: 100%;
        font-weight: 600;
    }
    .cusbtnFlx .text-danger {
        margin-top: -5px
    }
}
@media (max-width: 1024px){
    .registration_wrapper{
        .right_section{
            margin-top: 80px;
        }
    }
}