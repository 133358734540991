.chapters {
    padding: 16px;
    border-radius: 8px;
    max-width: 220px;
    width: 100%;
}
.chapters h4 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
}
.cenerHead{text-align: center;}
.chapters p {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
}
.alertBox {
    border: 1px solid #F4B856;
    border-radius: 8px;
    background: #FFFBF5;
    padding:15px 15px;
}
.FilterBtnBox .swiper-scrollbar {
    display: none !important;
}
.FilterBtnBox .swiper-pagination {
    position: relative;
    margin: -5px;
}
.FilterBtnBox .swiper-wrapper {
    height: 65px;
}

.alertBox p{font-size:18px;line-height: 25px;font-weight: 400;color: #000;}
.alertBox span{ max-width:34px; width: 100%;}

.subjectPg button.lightGreen {
    background: #C9F9D6 !important;
    border: none;color: #000;
}

 button.blueBtn {
    background: #fff !important;
    border: 1px solid #005DB8;
    color: #005DB8;
}

 button.blueBtn:hover {
    background: #005DB8 !important;
    /* border: none; */
    color: #fff;
}

 button.lightGreen {
    background: #C9F9D6 !important;
    border: none;
}

 button.darkYellow {
    background: #FFB800 !important;
    border: none;color: #000;
}


