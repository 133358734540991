.mptFlex{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 30px;
}
.mptBox{
    background-color: #ffffff;
    border-radius: 8px;
    padding: 7px 8px 7px 20px;
    display: flex;
    align-items: center;
}
.mptBoxWrap{
    display: flex;
    gap: 10px;
}
.snFlex{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}
.snfBox{
    padding: 8px 15px;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #ffffff;
    border-radius: 8px;
}
.snfBox p{
    color: #000000;
}
.subjectFlex{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;
    margin-bottom: 20px;
    max-width: 706px;
    overflow-x: auto;
}
.subjectflBox{
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #E6EFF8;
    border-radius: 8px;
    min-width: 165px;
    max-width: 165px;
}
.cirBox{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #E6EFF8;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
}
.bigSubjectCard{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
}
.bigSubjecBox{
    width: 50%;
    padding: 15px;
    position: relative;
}
.bigCircular{
    min-width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #E6EFF8;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
}
.bigSubjecBox{
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #E6EFF8;
    border-radius: 8px;
    width: 50%;
}
.bigSubjectBoxHead{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 12px;
}
.chapProStart{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 12px;
}
.chapDot{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    background-color: #EB0029;
}
.chapProgresWrap{
    padding: 8px;
    background-color: #E6EFF8;
    border-radius: 8px;
}
.chapProgresWrap .css-ab353j-MuiLinearProgress-root.MuiLinearProgress-colorPrimary{
    background-color: #ffffff;
}
.practiceTestContainer .css-1pgo5t3{
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}
.bigSubjecBox .dotSubjectBox{
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #E6EFF8;
    position: absolute;
    top: 10px;
    right: 10px;
}
.bigSubjecBox.active .dotSubjectBox{
    background-color: #005DB8;
}
[dir="rtl"] .bigSubjecBox .dotSubjectBox{
    right: unset;
    left: 10px;
}
@media only screen and (max-width: 576px) {
    .bigSubjectCard{
        flex-wrap: wrap;
    }
    .bigSubjecBox{
        width: 100%;
    }
  }