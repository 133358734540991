.heroSection {position: relative;background: #0F4D92; min-height:250px;}
.heroSection::after,
.heroSection::before{position: absolute;content: "";bottom: -1px; top: auto;  background-size: cover; background-repeat: no-repeat;}
.heroSection::after{background-image: url(../../assets/images/banner-left-img.png);width:418px; height:240px;left: 0; right: auto;}
.heroSection::before{background-image: url(../../assets/images/banner-right-img.png);width: 435px; height:250px;right:0;left:auto;}
.heroSection .MuiTypography-h2{font-size:40px;line-height:48px;font-weight: 700;letter-spacing:1px; color: #fff; margin-bottom: 15px;text-transform: capitalize;
    display: flex;
    justify-content: center;
}
.heroSection .MuiTypography-h2 span{color: #EA4435;}
.heroSection p{font-size: 18px !important; line-height: 28px;font-weight: 500;color: #fff;}
.heroIn{display: flex; max-width: 1130px; width: 100%; margin: 0 auto; padding:0px 15px 100px 15px;}
.banner-image {max-width: 200px;width: 100%;}

.banner-content {max-width: 615px;width: 100%; margin: 0 auto;}