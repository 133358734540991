.inpFlex {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    gap: 16px;
    width: 100%;
    margin-bottom: 24px;

    .MuiBox-root.css-0 {
        width: calc(33.33% - 11px);
    }

    .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
        width: 100%;
        /* border: 1px solid #555555; */
        /* border-radius: 8px; */
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        background-color: #F5F5F5;
        border-radius: 4px;
        padding: 9px 16px;

        &::placeholder {
            text-transform: capitalize;
        }
    }

    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border-color: #F5F5F5;
        border-radius: 4px;
    }
}

.personalRadio {
    .css-wxwe5p-MuiButtonBase-root-MuiRadio-root {
        padding: 0;
    }

    &.css-dmmspl-MuiFormGroup-root {
        flex-direction: row;
        gap: 16px;
        margin-bottom: 24px;
    }

    .css-j204z7-MuiFormControlLabel-root {
        margin: 0;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        width: 33.33%;
    }
}

button.blueBtn.saveBtn {
    /* min-height: 42px;
    min-width: 105px; */
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    border-radius: 8px;
}

.personalSelect {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-bottom: 24px;
    width: 100%;
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        width: 33.33%;
        margin: 0;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: nowrap;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            right: 24px;
            top: 13px;
            width: 8px;
            height: 8px;
            border-right: 1px solid #555555;
            border-bottom: 1px solid #555555;
            transform: rotate(45deg);
            pointer-events: none;
        }
        [dir="rtl"]{
            &::after{
                right: unset;
                left: 24px;
            }
        }
    }
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
        padding: 8px 16px;
        color: #555555;
        font-size: 14px;
        font-weight: 500;
        background-color: #F5F5F5;
        border-radius: 8px;
    }
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
        /* border: none; */
        border-radius: 8px;
        color: black;
    }
    &.academicSelect{
        flex-wrap: wrap;
        .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
            width: calc(33.33% - 11px);
        }
    }
    .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon, .css-bpeome-MuiSvgIcon-root-MuiSelect-icon{
        display: none;
    }
    >.MuiFormControl-root {
        width: calc(33.33% - 11px);
        >.MuiInputBase-root {
            width: 100%;
        } 
    }
    &.langPre>.MuiFormControl-root {
        width: calc(50% - 8px);
        >.MuiInputBase-root {
            width: 100%;
        } 
    }
}
.user-tab-wrapper{
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #D0DFF0;
}
.tab-wrapper{
    .css-1gsv261{
        border-bottom: none;
    }
}
@media (max-width: 576px){
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
        width: 100%;
    }
    .inpFlex {
        flex-wrap: wrap;
        .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
            width: 100%;
        }
    }
    .personalSelect {
        &.langPre {
            flex-wrap: wrap;
            .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
                width: 100%;
            }
        }
        &.academicSelect {
            flex-wrap: wrap;
            .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
                width: 100%;
            }
        }
    }
}