.ReferWrap{
    max-width: 420px;
    margin: 0 auto;
    text-align: center;
    padding: 0 16px 30px;
    >img{
        margin: 0 auto;
    }
    .referrewardImg{
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        padding-top: 30px;
    }
}
.friendCredit{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    max-width: 200px;
    margin: 0 auto 16px;
    border: 1px solid #E6E7E8;
    border-radius: 10px;
    text-align: left;
}
.copyRefer{
    background-color: #FFFBF5;
    border: 1px solid #F4B856;
    border-radius: 4px;
    padding: 16px 20px;
    max-width: 365px;
    margin: 0 auto 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    >div{
        text-align: left;
    }
}
body[dir=rtl] .copyRefer{
    >div{
        text-align: right;
    }
}
.shareSocial {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-bottom: 20px;

    .shareBtn,
    .whatsappBtn {
        padding: 5px;
        min-width: 140px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        color: #ffffff;
        border-radius: 23px;
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
        transition: none; /* Disable any transitions */
    }

    .shareBtn {
        background-color: #005DB8;
    }

    .whatsappBtn {
        background-color: #25D366;
    }

    /* Remove hover effect */
    .shareBtn:hover{
        background-color: #005DB8;/* Ensure the background stays the same */
    }
    .whatsappBtn:hover {
        background-color: #25D366;/* Ensure the background stays the same */
    }
}

.redeem{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin-bottom: 20px;
    .redeemBtn{
        padding: 5px;
        min-width: 200px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EA4435;
        gap: 15px;
        color: #ffffff;
        border-radius: 23px;
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
    }
}
.refTot{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-bottom: 20px;
    >.refTotBox{
        padding: 10px;
        border: 1px solid #EBEBEB;
        border-radius: 8px;
        background-color: #ffffff;
        min-width: 175px;
        max-width: 175px;
        min-height: 75px;
        text-align: left;
    }
}
.creditrecord{
    min-width: 365px;
    max-width: 365px;
    .crFlex{
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.crHead{
            background-color: #E6EFF8;
            .crFlexRight{
                padding: 16px;
                text-align: center;
                border-left: 1px solid #B0CDE9;
            }
        }
        .crFlexLeft{
            min-width: 200px;
            max-width: 200px;
            text-align: center;
            padding: 8px 16px;
            text-align: left;
        }
        .crFlexRight{
            min-width: 165px;
            max-width: 165px;
            text-align: center;
            padding: 8px 16px;
        }
    }
    .crBody{
        >div{
            border: 1px solid #EBEBEB;
        }
    }
}
.whiteBg{
    background-color: #ffffff;
    border-radius: 16px;
}
.selectCredit{
    .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
        &:hover{
            outline: none;
        }
    }
    .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
        padding: 5px;
        background-color: #ffffff;
        font-size: 16px;
        font-weight: 500;
        color: #555555;
    }
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
        border-color: transparent;
    }
    .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon, .css-bpeome-MuiSvgIcon-root-MuiSelect-icon{
        display: none !important;
    }
}
@media(max-width:576px){
    .creditrecord{
        min-width: 100%;
        max-width: 100%;
    }
    .refTot {
        flex-direction: column;
        >.refTotBox {
            min-width: 100%;
            max-width: 100%;
        }
    }
    .friendCredit{
        max-width: 100%;
    }
}