.oc_headwrap {
    display: flex;
    margin-bottom: 24px;
}

.oc_headleft {
    width: 100%;
}

.oc_headleft ul li {
    width: auto;
    gap: 8px;
    padding: 0;
}

.active-dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #4AB618;
}

.headright span {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
    font-size: 18px;
}

.session_span {
    color: #005DB8;
    background-color: #EAF4FF;
    border-radius: 4px;
    font-size: 20px;
    list-style: none;
    line-height: 1.2;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    min-width: 186px;
    margin-bottom: 16px;
}

.item-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}

.session_grid_wrap {
    width: 100% !important;
    margin-left: 0 !important;
    flex-wrap: nowrap !important;
    gap: 20px;
    margin-top: 20px !important;
}

.session_grid_wrap>div {
    /* border: 1px solid #F5F5F5;
    padding: 8px; */
    width: 100%;
}

.sessionBy {
    background-color: #EAF4FF;
    padding: 16px;
    border-radius: 16px;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    >.sessionBox{
        .sessionBtn{
            >:first-child{
                margin-left: 46px;
            }
        }
    }
}

.sessionBox {
    width: 50%;
}

.consularBox {
    width: 100%;
}

.spro-card {
    display: flex;
    gap: 12px;
}

.sessionBox h5 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #000;
}

.sessionBtn {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 8px;
}

.sessionBy .sessionBtn .blueBtn {
    width: auto;
}

.sessionBy .bdrbtn {
    border: 1px solid #005DB8;
    color: #005DB8;
    padding: 2px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    text-transform: capitalize;
    border-radius: 4px;
    min-height: 32px;
}

.email-mob {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 0;
}

.sb-short {
    max-width: 280px;
}

.sb-max {
    padding-left: 65px;
}

.sessionBox .RankInfo {
    min-height: 42px;
}
.custom-panel-wrapper .RankInfo{
    background-color: #EAF4FF;
    margin-top: 16px;
    padding: 16px;
    border-radius: 8px;
}
.headbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.headbox h4 {
    font-size: 20px;
    font-weight: 600;
    font-family: 'Rajdhani', sans-serif !important;
    color: #005DB8;
}

.outerCard {
    min-height: 300px;
    background-color: #fff;
}

.oCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.customToggle {
    background-color: #fff;
    border-radius: 20px;
}

.customToggle>div>button {
    min-width: 80px;
    padding: 4px 10px;
    border: none;
    color: #005DB8;
    text-transform: capitalize;
    font-family: 'Rajdhani', sans-serif !important;
}

.customToggle>div>button:first-child {
    border-radius: 20px;
}

.customToggle>div>button:last-child {
    border-radius: 20px;
}

.customToggle>div>button.MuiButtonBase-root.MuiToggleButtonGroup-grouped.Mui-selected {
    background-color: #005DB8;
    color: #fff;
}
.token_sessions .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    display: none;
}
.token_sessions .swiper-wrapper{
    padding-bottom: 0;
}
.token_sessions{
    .swiper-button-prev, .swiper-button-next { 
        padding: 10px;
        width: 25px;
        height: 25px;
        border-radius: 4px;
        background: #005db8;
        &::after{
            color: #fff;
        }
    }
    .swiper-button-next:after, .swiper-button-prev:after{
        font-size: 14px;
    }
    .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled{
        background: #e7e3e3;
        opacity: 0.7;
        pointer-events: auto;
    }
    .swiper-pagination{
        display: none;
    }
}