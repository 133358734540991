/* .FooterMain { background: #F9FCFF; padding: 50px; } */
.FooterMain { background: #F9FCFF; padding: 20px; }
.FooterMain h6 { color: #0F4D92; font-size: 20px; line-height: 30px; font-weight: 600; text-transform: capitalize; }
.footerIn { display: block; text-align: center; }
.contactUs { margin: 20px 0px; }
.contactUs a { color: #555555; font-size: 18px; line-height: 25px; font-weight: 500; }
.contactUs a:hover { color: #0F4D92; }
.contactUs a span > img { min-width: 24px; }
.contactUs { gap: 20px; }
.contactUs a { display: flex; align-items: center; gap: 4px; }
.socialBox { gap: 10px; }
.socialBox a { width:34px; height:34px; border: 1px solid #727272; display: flex; justify-content: center; align-items: center; border-radius: 50%; transition: all 0.3s ease-in-out; }
.socialBox a:hover { background: #005DB8; border: 1px solid #005DB8; ; }
.socialBox a:hover svg path { fill: #fff; }
.copyRight p { font-size: 14px; line-height: 18px; font-weight: 400; text-align: center; }
[dir="rtl"] .contactUs a:first-child span > img { transform: rotate(265deg); }
