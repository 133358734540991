:root {
  --light-gray:#F9FCFF;
  --cream:#FFF6DF;
  --sky:#E6EFF8;
  --pink:#FFDBE1;
  --light-pink:#FCEEEE;
  --light-green:#C9F9D6;
  --green:#B1F2C2;
  --darkYellow:#FFB800;
  --light-yellow:#FFFBF5;
  --mid-yellow:#FFEBCE;
  --blue: #E6EFF8;
  --dark-blue:#005DB8;

  }

.lightGray{background:var(--light-gray);}
.cream {background: var(--cream);}
.sky{background: var(--sky);}
.pink{background: var(--pink);}
.lightPink{background: var(--light-pink);}
.lightGreen{background:var(--light-green);}
.lightYellow{background: var(--light-yellow);}
.midYellow{background: var(--mid-yellow);}
.green{background: var(--green);}
.darkYellow{background: var(--darkYellow);}
.blue{background: var(--blue);}
.darkBlue{background:var(--dark-blue) ;}

.orgbdr{border: 1px solid #F4B856;}
.radius8{border-radius: 8px;}
.radius4{border-radius: 4px;}
.bdrDash{border-bottom: 1px dashed #ccc;}
.lightbdr{border: 1px solid #E6EFF8;}


h3{font-size: 24px;line-height: 30px;font-weight: 600; color:#000;}

/* css add by amit */
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}

/* chirayu css */
.flagdrop .css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  border: 1px solid #f5f5f5;
  min-width: 150px;
  min-height: 58px;
  justify-content: flex-start;
  position: relative;
  padding: 12px 10px;
  font-size: 20px;
  font-weight: 600;
  color: #555555;
  text-transform: capitalize;
  background-color: #ffffff !important;
  #flag-icons-us{
      position: absolute;
      right: 32px;
  }
}
[dir=rtl] .flagdrop .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  #flag-icons-us{
      right: unset;
      left: 32px;
  }
}
[dir=rtl]{
  right: unset;
  left: auto;
  .flagdrop .css-1e6y48t-MuiButtonBase-root-MuiButton-root::after{
      right: unset;
      left: 14px;
  }
}
[dir=rtl]{
  .flagdrop .css-1e6y48t-MuiButtonBase-root-MuiButton-root{
      justify-content: flex-start;
      padding-left: 30px;
      #flag-icons-kw{
          margin-right: 10px;
      }
  }
}

.flagdrop .css-1e6y48t-MuiButtonBase-root-MuiButton-root .css-i4bv87-MuiSvgIcon-root{
  display: none;
}
.flagdrop .css-1e6y48t-MuiButtonBase-root-MuiButton-root{
  position: relative;
}
.flagdrop .css-1e6y48t-MuiButtonBase-root-MuiButton-root::after{
  content: '';
  position: absolute;
  top: 40%;
  right: 10px;
  transform: translate(-50%) rotate(45deg);
  width: 8px;
  height: 8px;
  border-right: 1px solid #727272;
  border-bottom: 1px solid #727272;
}
#simple-menu .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  min-width: 150px;
  max-width: 150px;
}
#simple-menu .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
  gap: 10px;
}

.widget-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  pointer-events: none;
}
.widget-container * {
  pointer-events: all;
}

.fcr-countdown-mobile-widget {
  position: relative;
}
.whiteboard-mobile-container .netless-whiteboard-wrapper {
  background-color: #fff;

  border-color: #fff;
  pointer-events: none;
}

.chat-setting-icon {
  width: 48px;
  height: 48px;
  background: #3A3A3A;
  padding: 8px 5px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}
.chat-setting-icon p{
  color: #d5d6da;
  font-size: 12px;
  text-align: center;
  font-weight: 500;
}
.chat-setting-icon svg{
  margin: 0 auto;
}
.fcr-hx-app.w-full.chat-box-wrapper {
  position: absolute;
  right: 55px;
  bottom: -65px;
  height: calc(100vh - 355px);
  width: 270.286px;
}

.popover-placement-top > .popover-content > .popover-arrow {
  border-color: #514f4f;
  background: #514f4f;
}

.fcr-hx-input-box.chat-action-wrapper {
  padding: 7px;
  border-style: none;
}
.fcr-hx-input-box.chat-action-wrapper .chat-actions {
  border: 1px solid #cecece;
  border-radius: 4px;
}
.chat-panel .fcr-hx-mini-icon {
  font-size: 30px;
  line-height: 1;
  text-align: center;
}


@media(max-width: 1600px) {
  .fcr-hx-app.w-full.chat-box-wrapper {
    height: calc(100vh - 310px);
    width: 224.571px;
  }
}
@media(max-width: 1366px) {
  .fcr-hx-app.w-full.chat-box-wrapper {
    height: calc(100vh - 255px);
    width: 179.429px;
  }
  .bottom-nav-wrap {
    width: calc(100% - 270px);
  }
}

.current-user .event-message {
  background: cornflowerblue;
}

.other-user .event-message {
  background: aliceblue;
}

.loader-wrapper {
  display: flex;
  justify-content: center;
}

.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.widget-slot-chat.camera_mode_off .fcr-hx-app.w-full.chat-box-wrapper {
  height: 100vh;
  bottom: 0;
  top: 0;
  position: fixed;
  z-index: 999;
  right: 63px;
}
.chat-actions textarea.ant-input.fcr-hx-input-chat {
  line-height: 1.2;
  min-height: 60px;
}

.widget-slot-chat.camera_mode_single .fcr-hx-app.w-full.chat-box-wrapper {
  height: calc(100vh - 270px);
  bottom: 10px;
  top: auto;
  position: fixed;
  z-index: 999;
  right: 63px;
}

.mb-0 {
  margin-bottom: 0px;
}

.custom-popup {
  border-radius: 20px !important; /* Increase modal border-radius */
}

.custom-confirm-button {
  background-color: #005DB8 !important; /* Change button color */
  color: white !important; /* Ensure text is visible */
  border-radius: 8px !important; /* Rounded corners */
  padding: 10px 20px !important; /* Adjust padding */
  font-size: 16px !important; /* Adjust font size */
}
