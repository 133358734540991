.tutor_cprofile {
    width: 314px;
    padding: 50px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 16px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.tutor_cprofile_inner {
    margin: 5px 0;
    text-align: center;
}

.tc_wrap {
    text-align: center;
    display: inline-block;
    max-width: 112px;
    border-radius: 7px;
    margin-bottom: 12px;
    position: relative;
    >img {
        min-width: 112px;
        max-width: 112px;
        max-height: 94px;
        border-radius: 8px;
        aspect-ratio: 3 / 4;
        width: 100%;
        object-fit: cover;
    }
}

.tc_wrap::after {
    background: #A0F533;
    height: 8px;
    width: 8px;
    top: 8px;
    right: 8px;
    border-radius: 50%;
    content: "";
    z-index: 9;
    display: flex;
    position: absolute;
}

.tutor_cprofile_inner .content-in {
    text-align: center;
}

.tutor_cprofile_inner .content-in>h3 {
    justify-content: center;
    gap: 8px;
    margin-bottom: 4px;
}

.tutor_cprofile_inner .RatingBox {
    margin-bottom: 8px;
}

.tutor_cprofile_inner .RatingBox>div {
    align-items: center;
}

.tutor_cprofile_inner .availableBtn {
    margin-top: 20px;
}

.tutor_cprofile_inner .availableBtn button {
    font-size: 18px;
}

.tutor_cprofile_inner .availableBtn button.bdrbtn {
    color: #EA4435;
    border: 1px solid #EA4435;
}

.tutor_cprofile_inner .availableBtn button:disabled {
    background-color: #F5F5F5;
    color: #D3D3D3;
    border: none;
}

@media only screen and (max-width: 767px) {
    .tutor_cprofile {
        padding: 30px 20px;
    }
}