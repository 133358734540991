.outerCard{
    min-height: 300px;
    background-color: #fff;
    border-radius: 16px;
    overflow: hidden;
    padding: 24px;
}
.headBox{
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EAF4FF;
    border-radius: 8px;
    margin-bottom: 30px;
}
.head_connect{
    display: flex;
    align-items: center;
    gap: 24px;
}
.head_connect>img{
    min-width: 84px;
    max-width: 84px;
    min-height: 84px;
    max-height: 84px;
    border-radius: 50%;
    border: 2px solid #ffff;
    object-fit: cover;
}
.c_box{
    padding: 16px;
    border-radius: 8px;
}
.inr_wht_box{
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    gap: 10px;
}
.icon_wrap{
    min-width: 36px;
    max-width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.icon_wrap.lt_yellow{
    background-color: #FFF6DF;
}
.lt_blue{
    background-color: #EAF4FF;
}
.inr_wht_wrap{
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
}
.inr_wht_wrap>.inr_wht_box{
    width: 50%;
}
.br_box{
    border: 1px solid #F5F5F5;
    width: 50%;
}

.notification_wrap{
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 12px;
}
.notification_wrap>div{
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
}

.notify_wrapper{
    margin-top: 30px;
}
.earning_box.br_box{
    width: calc(50% - 10px);
}
.on_off_flex{
    display: flex;
    align-items: center;
    gap: 7px;
}
.online_head{
    background-color: #F8FAF4;
}
.online_head .css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
    background-color: #7CB66F;
}
.online_head .on_off_flex .css-i3hj12-MuiTypography-root{
    color: #7CB66F;
}
.online_head .head_connect .css-ynk53s-MuiTypography-root{
    color: #7CB66F;
}
.backBtn span svg {
    font-size: 14px !important;
}
@media only screen and (max-width: 1199px) {
    .containerShape::after, .containerShape::before{
        display: none;
    }
  }
@media only screen and (max-width: 900px) {
    .OuterContainer {
        border: none;
        margin-bottom: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .OuterContainer {
        margin: -80px auto;
        margin-bottom: 0px;
    }
    .OuterContainer.containerShape {
        padding: 15px;
    }
    .outerCard{
        padding: 16px;
    }
    .inr_wht_wrap{
        flex-wrap: wrap;
    }
    .inr_wht_wrap>.inr_wht_box{
        width: 100%;
    }
    .quikCallBanner {
        padding-left: 15px;
        padding-right: 15px;
    }
    .quikCallBanner h1 {
        margin-top: 0 !important;
    }
    .earning_box.br_box{
        width: 100%;
    }
  }
.blue_btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 500;
    background-color: #005DB8;
    border: 1px solid #005DB8;
    color: #fff;
    border-radius: 4px;
    text-transform: capitalize;
}
.blue_btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
    color: #005DB8;
    background-color: transparent;
}
.bl_wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}
.filterHead{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}
.payment_table .MuiTableCell-root.MuiTableCell-head{
    background: #005DB8;
    color: #fff;
    text-transform: capitalize;
}
.filterHead .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    height: 34px;
    /* min-width: 85px; */
    min-width: 120px;
    color: #005DB8;
    position: relative;
    background: #EAF4FF;
    font-weight: 500;
    text-transform: capitalize;
}
.filterHead .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root::after{
    content: '';
    position: absolute;
    right: 10px;
    top: 11px;
    width: 7px;
    height: 7px;
    border-right: 1px solid #005DB8;
    border-bottom: 1px solid #005DB8;
    transform: rotate(45deg);
}
.filterHead .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding-right: 20px;
}
.filterHead .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
border: none;
}
.filterHead .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon, .filterHead .css-bpeome-MuiSvgIcon-root-MuiSelect-icon{
    display: none;
}
.payment_table .css-1enotpu-MuiTableRow-root:nth-of-type(odd){
    background-color: #fff;
}
.payment_table .css-apqrd9-MuiTableBody-root tr.css-1enotpu-MuiTableRow-root:last-child{
    /* background-color: #F9FCFF; */
}
.payment_table .css-132a6sb-MuiTableCell-root{
    color: #000000;
    border-bottom: 1px solid #EAF4FF;
}
.payment_table .css-11xur9t-MuiPaper-root-MuiTableContainer-root{
    box-shadow: none;
}
.payment_table .css-apqrd9-MuiTableBody-root tr.css-1enotpu-MuiTableRow-root:last-child .css-132a6sb-MuiTableCell-root{
    /* color: #005DB8;
    font-weight: 500; */
}
.payment_table .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 6px;
    min-width: 50px;
    color: #005DB8;
}
.HireTutorContainer .outerCard{
    min-height: auto;
}
.videoSection .outerCard{
    padding: 0;
}
.quikCallBanner>.OuterContainer{
    overflow: hidden;
}