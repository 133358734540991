.OuterContainer {
  position: relative;
  border: 1px solid #f5f5f5;
  max-width: 768px;
  width: 100%;
  margin: -120px auto;
  margin-bottom: 100px;
  border-radius: 18px;
  overflow: unset;
  /* border-top-right-radius: 18px; */
  /* border-top-left-radius: 18px; */
}
.studyContainer h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: #000;
}
.studyContainer p {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  color: #555555;
}
.studyContainer .practiceBox .MuiTypography-h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 1px;
}
.studyBanner {
  padding: 15px 15px 0px 15px;
  background: #ffe9ce;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}
.studyBanner .imageBox {
  max-width: 190px;
}
.practiceBox {
  padding: 0px 30px 40px 30px;
}
.cardMain {
  border: 1px solid #e7e7e7;
  border-radius: 16px;
  padding: 16px;
  width: 100%;
  display: block;
}
.cardLeft {
  /* max-width: 145px; */
  width: 100%;
  display: flex;
  justify-content: end;
}
.cardLeft button {
  font-size: 14px !important;
  line-height: 25px;
  font-weight: 600;
  border-radius: 8px;
  padding: 5px 15px;
  box-shadow: none;
  outline: none;
  text-transform: capitalize !important;
  border: 1px solid #005db8;
  background: #fff !important;
  color: #005db8;
}
.cardLeft button:hover {
  background: #005db8 !important;
  color: #fff;
}
.cardIcon {
  background: #f9fcff;
  max-width: 38px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  color: #005db8;
  font-size: 20px;
  font-weight: 600;
  padding: 5px;
}
body[dir="rtl"] .cardIcon {
  margin-left: 10px;
  margin-right: 0;
}

.cardContent h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-transform: capitalize;
}
.cardList ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
}
.cardList ul li {
  position: relative;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #555555;
  margin: 0px 12px;
}
.cardList ul li:first-child {
  margin-left: 0;
}
body[dir="rtl"] .cardList ul li:first-child {
  margin-right: 0;
}
.cardList ul li:after {
  content: "";
  width: 1px;
  height: 12px;
  background: #555555;
  position: absolute;
  top: 9px;
  left: -12px;
}
.cardList ul li:first-child::after {
  display: none;
}
body[dir="rtl"] .cardList ul li:first-child::after {
  display: block;
}

body[dir="rtl"] .detailFull ul li:last-child:after {
  display: none;
}
.cardBottom {
  padding: 16px;
  margin-top: 15px;
  border-radius: 8px;
  background: #f9fcff;
}
.progressBar > span {
  background: #e6eff8;
  border-radius: 16px;
}
.progressBar > span > span {
  background: #005db8;
  border-radius: 16px;
}
.cardBottom h5 {
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  text-transform: capitalize;
}
.progressBar {
  margin: 12px 0px 15px 0px;
}
.progressNumber {
  display: flex;
  gap: 10px;
}
.progressNumber span {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  display: flex;
  padding: 7px 12px;
  border-radius: 30px;
  color: #000;
}
.understood {
  background: #ffe9ce;
}
.practicing {
  background: #e6eff8;
}
.notStarted {
  background: #ffdbe1;
}

/* question paper */
.questionPg {
  padding: 0px;
}
.shareBox {
  border: 1px solid #f4b856;
  border-radius: 8px;
  background: #fffbf5;
  padding: 5px 15px;
  min-height: 60px;
}
.shareBox span {
  margin-right: 12px;
}
body[dir="rtl"] .shareBox span {
  margin-left: 12px;
  margin-right: 0px;
}
.shareBox p {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  text-transform: capitalize;
}
.practiceBox .beforeTest h3 {
  margin: 0;
  text-transform: capitalize;
}
.beforeTest h3 span {
  color: #005db8;
}
.TestYourSelf .cardIcon {
  background: #fffbf5;
}
.TestYourSelf {
  background: #fffbf5;
  padding: 16px;
}
.TestYourSelf h3 span {
  color: #f4b856;
  text-transform: capitalize;
}
.SolutionBox h3 {
  text-transform: capitalize;
}
.materialBox {
  background: #f9fcff;
  padding: 16px;
}

.materialBox .cardMain {
  background: #fff;
}

.cardSimple .cardMain {
  border: none;
  padding: 0px;
}
.samplePdf .cardTop {
  border-bottom: 1px solid #e6eff8;
  padding: 10px 0px;
}
.samplePdf .cardTop:last-child {
  border: none;
  padding-bottom: 0px;
}
.solutionBanner {
  position: relative;
  background: #f9fcff;
  padding: 15px 15px 0px 15px;
  padding-bottom: 0px;
  margin-bottom: 15px;
}
.solutionBanner p {
  text-transform: capitalize;
}

/* .solutionBanner::after{content: ""; background-image: url(../../assets/images/textfile.png); width:50px; height:36px;position: absolute;
    right: 0;top: 0;} */

.booktopIcon {
  position: relative;
}
.booktopIcon::after {
  content: "";
  background-image: url(../../assets/images/textfile.png);
  width: 50px;
  height: 36px;
  position: absolute;
  right: 0;
  top: 0;
}

.studyContainer .practiceBox .solutionBanner h3 {
  margin: 0px;
  text-transform: capitalize;
}
.studyContainer .practiceBox .solutionBanner p {
  margin-bottom: 5px;
}
.solutionBanner .contentBox {
  margin-bottom: 15px;
}
.rightSolution {
  background: #fffbf5;
}

/* question bank page */
.QuestionHead {
  padding: 20px 0px 0px 0px;
  text-align: center;
}
.QuestionHead h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #000;
}
.QuestionHead ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px;
}
.QuestionHead ul li {
  position: relative;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #555555;
  margin: 0px 12px;
}
.QuestionHead ul li:after {
  content: "";
  width: 1px;
  height: 12px;
  background: #555555;
  position: absolute;
  top: 9px;
  left: -12px;
}
.QuestionHead ul li:first-child::after {
  display: none;
}
.QuestionHead ul li:first-child {
  margin-left: 0;
}

.chaptersBox {
  gap: 20px;
}
.chapterBoxIn {
  width: 50%;
  padding: 15px;
  border-radius: 8px;
}
.chapterBoxIn span {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}
.mainHeading h3 {
  text-transform: capitalize;
}

.questionView .understood {
  background: #b1eef2;
}
.questionView .practicing {
  background: #b1f2c2;
}
.questionView span {
  font-size: 14px;
  color: #000;
  text-transform: capitalize;
}
.questionSet1 .cardLeft {
  justify-content: start;
}

.selectOption p {
  text-transform: capitalize;
}
.questioList .cardIcon {
  border: 1px solid #e6eff8;
}

.questionListIn {
  padding: 10px;
}

.questionListIn .cardIcon {
  font-size: 18px;
}
.questionListIn p span {
  display: block;
  font-size: 14px;
}

.studyContainer .practiceBox .QuestionSolution h3 {
  margin: 0px;
  padding: 0;
  text-transform: capitalize;
}
.YellowCircle {
  background: #ffb800;
  border-radius: 50%;
  padding: 5px;
}
.solutionBanner .imageBox {
  max-width: 95px;
  width: 100%;
}
.rightSolution {
  padding: 15px;
}
.rightSolution h3 {
  text-transform: capitalize;
}
.solutionSmall {
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.tuterBtn {
  background: #005db8;
  color: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  max-width: 150px;
  width: 100%;
}
.tuterBtn span svg {
  margin-right: 5px;
  transform: rotate(115deg);
}

.checkAnswerBtn {
  background: #005db8;
  border-radius: 8px;
  font-size: 18px;
  color: #fff;
}
.checkAnswerBtn {
  background: #005db8;
  border-radius: 8px;
  font-size: 18px;
  color: #fff;
  padding: 8px 15px;
  text-transform: capitalize;
  max-width: 200px;
  width: 100%;
  text-align: center;
}

body[dir="rtl"] .QuestionSolution .shareBox span,
body[dir="rtl"] .bodyrightSolution span {
  margin-left: 12px;
}
.QuestionSolution .cardMain {
  padding: 10px 16px 10px 16px;
}
body[dir="rtl"] .understoodBox span {
  margin-left: 8px;
}
.tuterBtn span svg {
  margin-right: 5px;
  transform: rotate(115deg);
}

body[dir="rtl"] .solutionSmall span {
  margin-left: 10px;
}
body[dir="rtl"] .tuterBtn span svg {
  transform: rotate(0deg);
  margin-left: 10px;
  margin-right: 0;
}

/* tabs css */
.tabsBoxTop {
  background: #e6eff8;
  padding: 15px;
}
.TabsBox button {
  border: 1px solid #005db8;
  /* border-radius: 45px; */
  color: #005db8 !important;
  font-size: 14px;
  font-weight: 600;
  margin-right: 10px;
  position: relative;
  z-index: 1;
  min-height: 38px;
  padding: 8px 15px;
  text-transform: capitalize;
  line-height: normal;
  border-bottom: transparent;
}

.TabsBox button.Mui-selected {
  color: #fff !important;
  border: 1px solid #1976d2;
}
.TabsBox .css-1aquho2-MuiTabs-indicator {
  height: 100%;
  width: 100%;
  background-color: #1976d2;
  border-radius: 45px;
  height: 38px;
  top: 0;
}

.TabsBox .css-1ujnqem-MuiTabs-root {
  min-height: auto;
}
.TabsBox .css-19kzrtu {
  padding: 15px 0px;
}
.questionType .cardIcon {
  background: #e6eff8;
}
.hardBox {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #ca6565;
}

.medium {
  background: #fffbf5;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #f4b856;
}
.TabsBox .cardTop {
  width: 100%;
}
.TabsBox .cardMain.sky {
  background: #f9fcff;
}
.TabsBox .rightSolution {
  background: #fffbf5;
}
.studyContainer .TabsBox p {
  text-transform: capitalize;
}
.TabsBox .YellowCircle {
  background: #f4b856;
}

/* mock test */

.attemptedCard {
  border: 1px solid #e6eff8;
  padding: 15px;
  border-radius: 8px;
}
.attemptedHeading p {
  margin: 0px 7px;
  font-size: 16px;
  text-transform: capitalize;
  color: #000;
}

.attemptedHeading p span {
  font-weight: 600;
}

.attemptedHeading .cardContent p {
  text-transform: capitalize;
}

.detailFull ul {
  justify-content: space-between;
}

.detailFull ul {
  justify-content: center;
  margin: 0;
}
.detailFull ul li {
  width: 33.33%;
  margin: 0;
  display: flex;
  justify-content: center;
  text-transform: capitalize;
}
.detailFull ul li strong {
  padding: 0px 5px;
}

.scoreBar .cardIcon {
  background: transparent;
  padding: 0;
  width: auto;
}
.scoreBar .cardTop {
  padding: 3px 15px;
}
.scoreBar .cardContent p span {
  font-weight: 600;
}
.scoreBar .cardLeft p {
  padding: 0px 5px;
}

.createTeachers .chapterBoxIn {
  border: 1px solid #ffebce;
}
.createTeachers .chapterBoxIn h3 span {
  display: block;
  color: #eb0029;
}
body[dir="rtl"] .scoreBar .cardLeft span svg {
  transform: rotate(180deg);
}

.createTeachers .chapterBoxIn {
  position: relative;
  padding-left: 55px;
}
.createTeachers .chapterBoxIn .iconsBox {
  position: absolute;
  left: -1px;
  top: 0px;
}

.ArIcon {
  display: none;
}
body[dir="rtl"] .ArIcon {
  display: block;
}
body[dir="rtl"] .engIcon {
  display: none;
}
body[dir="rtl"] .chapterBoxIn .iconsBox {
  right: 0;
}
body[dir="rtl"] .createTeachers .chapterBoxIn {
  padding-right: 55px;
  padding-left: 15px;
}

.practiceBox .scoreBar .cardTop,
.practiceBox .scoreBar .cardRight {
  display: flex;
}
.practiceBox .scoreBar .cardLeft {
  max-width: 50px;
}

/*  */
.continueBox {
  display: flex;
  justify-content: flex-end;
}
.continueBtn {
  display: flex;
  align-items: center;
  border: 1px solid #005db8;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 500;
  color: #005db8;
  border-radius: 45px;
  padding: 7px 15px;
  max-width: 220px;
  width: 100%;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.continueBtn:hover {
  background: #005db8;
  color: #fff;
}
.continueBtn:hover span svg path {
  stroke: #fff;
}
.continueBtn span {
  margin: 0px 5px;
}
body[dir="rtl"] .continueBtn span svg {
  transform: rotate(180deg);
}

.reproductionBox .cardMain .cardLeft {
  width: auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

/* accordion */
/* .accordionMain .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
    background: #F9FCFF !important;border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.css-pwcg7p-MuiCollapse-root::before{display: none;}
.accordionMain .css-eqpfi5-MuiAccordionSummary-content.Mui-expanded{margin:10px 0 !important;}
.accordionMain .css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 50px !important;border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #F5F5F5; 
}

.accordionMain .css-15v22id-MuiAccordionDetails-root {
    padding: 8px 16px 16px;
    border: 1px solid #F5F5F5;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
} */

/* .css-i4bv87-MuiSvgIcon-root {
    color: #005DB8;
    font-size: 30px !important;
}
 */
.accordionMain .MuiAccordionSummary-root {
  background: #f9fcff;
}

.MuiAccordion-rounded {
  min-height: 50px !important;
  margin-bottom: 20px;
  border-radius: 8px !important;
  border: 1px solid #e6eff8 !important;
  box-shadow: none !important;
}
.MuiAccordionSummary-content {
  margin: 12px 0px !important;
}
.QuestionAccordion .MuiSvgIcon-fontSizeMedium,
.accordionMain .MuiSvgIcon-fontSizeMedium {
  font-size: 30px !important;
  color: #005db8;
}
.MuiAccordion-rounded::before {
  display: none;
}
.MuiCollapse-entered::before {
  display: none;
}
.cardIcon {
  max-width: 32px;
  width: 32px;
  height: 32px;
  font-size: 18px;
}
.accordionMain p {
  color: #005db8;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 600;
}

.accordionMain ul li {
  position: relative;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  padding: 5px 0px 5px 25px;
}

.accordionMain ul li:after {
  width: 5px;
  height: 5px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 15px;
  content: "";
}

.accordionMain .cardBottom h5 {
  font-size: 18px;
}
.accordionMain .cardBottom {
  padding: 8px 15px;
  margin-top: 5px;
}
body[dir="rtl"] .MockTestContent .continueBtn span svg {
  transform: rotate(0deg);
}
.accordionMain .InnerBorder {
  border: 1px solid #f5f5f5;
  border-radius: 16px;
  padding: 0px;
  width: 100%;
  display: block;
}
.accordionMain .InnerBorder .cardMain {
  border-radius: 0;
  padding: 16px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #f5f5f5;
}
.accordionMain .InnerBorder .cardMain:last-child {
  border: none;
}

/* restult css */

.answerList .imageBox img {
  width: 28px;
}
.answerList .imageBox {
  padding: 5px 10px;
}

.anskeybtn {
  background: #005db8;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 30px;
  border-radius: 35px;
  border: 1px solid #005db8;
  transition: all 0.3s ease-in-out;
}
.anskeybtn:hover {
  background: #fff;
  color: #005db8;
}
.sectionBox .chapterBoxIn {
  width: 100%;
}
.sectionBox .contentBox h3,
.sectionBox .contentBox h4,
.sectionBox .contentBox p {
  text-transform: capitalize;
}

.sectionBox .contentBox h3 {
  color: #005db8;
}
.sectionBox .contentBox h4,
.sectionBox .contentBox h4 span,
.sectionBox .contentBox p,
.sectionBox .contentBox p span {
  font-size: 18px;
  line-height: 24px;
}

.sectionBox .cardBottom {
  border-radius: 0px;
  background: #fff;
}
.progressText span {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
}

.progressBar.pink span span {
  background: #faa2a2;
}

.progressBar.blackbg span span {
  background: #555;
}
.blueText {
  color: #005db8;
}
.pinkText {
  color: #faa2a2;
}

.FilterBtnBox {
  gap: 15px;
  display: flex;
}
.FilterBtnBox .swiper-wrapper .swiper-slide>button.MuiButtonBase-root {
  border: 1px solid #727272;
  border-radius: 35px;
}

.FilterBtnBox .swiper-wrapper .swiper-slide>button.MuiButtonBase-root {
  border: 1px solid #727272;
  border-radius: 35px;
  font-size: 18px;
  text-transform: capitalize;
  color: #727272;
  font-weight: 600;
  min-width: 190px;
}

.FilterBtnBox .swiper-wrapper .swiper-slide.swiperslide-active>button.MuiButtonBase-root, .FilterBtnBox .swiper-wrapper .swiper-slide>button.MuiButtonBase-root:hover{
  background: #fff;
  color: #000;
  border: 1px solid #fff;
}
  
.QuestionAccordion .cardRight h4 {
  color: #005db8;
  text-transform: capitalize;
}
.FilterBtnBox {
  overflow-x: scroll;
  /* -ms-overflow-style: none;
        scrollbar-width: none; */
}

.checkAnswer .checkAnswerBtn {
  background: #fff;
  color: #005db8;
  border: 1px solid #005db8;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.checkAnswer .checkAnswerBtn:hover {
  background: #005db8;
  color: #fff;
}

/* Hide horizontal scrollbar for Webkit browsers (Chrome, Safari) */
.FilterBtnBox::-webkit-scrollbar {
  width: 0;
  height: 0; /* Remove scrollbar space */
}

/* Hide scrollbar for other browsers (Firefox, Edge) */
.FilterBtnBox {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  overflow-x: scroll; /* Ensure horizontal scroll */
  overflow-y: hidden; /* Hide vertical scroll */
  white-space: nowrap; /* Prevent line breaks */
}

/* Show horizontal scrollbar on hover */
.FilterBtnBox:hover::-webkit-scrollbar {
  height: 8px; /* Adjust the height as needed */
}

/* Customize scrollbar track and thumb */
.FilterBtnBox::-webkit-scrollbar-track {
  background: #f1f1f1; /* Scrollbar track color */
}

.FilterBtnBox::-webkit-scrollbar-thumb {
  background: #888; /* Scrollbar thumb color */
}

/* Customize scrollbar thumb on hover */
.FilterBtnBox::-webkit-scrollbar-thumb:hover {
  background: #555; /* Scrollbar thumb hover color */
}

.attemptedCard .cardTop .cardContent p {
  font-size: 16px;
}

.attemptedCard .cardTop .cardContent.attemptStatus p {
  font-size: 18px;
}