.body {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }
  .webversion {
    display: block;
  }
  
  img {
    max-width: 100%;
    height: auto;
  }
  p {
    margin: 0;
  }
  table td {
    border-collapse: collapse !important;
    mso-line-height-rule: exactly;
    padding: 0;
  }
  * {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: none;
    -webkit-text-resize: 100%;
    text-resize: 100%;
  }
  a {
    outline: none;
    color: #004655;
    text-decoration: none;
  }
  a:hover {
    text-decoration: none !important;
  }
  a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
  }
  table {
    display: table;
    border-collapse: collapse;
  }
  table,
  td {
    mso-table-lspace: 0pt !important;
    mso-table-rspace: 0pt !important;
  }
  .container {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
  }
  
  .tableHead thead tr td {
    padding: 15px 15px;
    background: #f1f1f1;
  }
  
  .tableHead tbody tr td {
    padding: 30px 15px;
  }
  .TableSummry tr td {
    padding: 5px 15px;
  }
  .totalAmt tr td {
    padding: 20px 15px;
    border: 1px solid #ceeff9;
  }
  
  .cis-right-shift{
    text-align: right;
  }
  
  @media (max-width: 767px) {
    .pdfMain {
      font-size: 16px;
    }
    .rightlogo img {
      width: 90px;
    }
  }
  