.quikCallBanner {
    background: #0F4D92;
    min-height: 390px;
    position: relative;
    padding-top: 140px;
    margin-bottom: -285px;
  }
  
  .containerShape {
    position: relative;
  }
  
  .containerShape::after,
  .containerShape::before {
    content: "";
    position: absolute;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1;
  }
  
  .containerShape::after {
    content: "";
    position: absolute;
    background-image: url("../../assets/images/owlimg1.png");
    width: 100px;
    height: 100px;
    left: -100px;
    top: 188px;
  }
  
  .containerShape::before {
    content: "";
    position: absolute;
    background-image: url("../../assets/images/owlimg2.png");
    width: 80px;
    height: 125px;
    right: -82px;
    top: 150px;
  }
  
  .yellowStar {
    position: absolute;
    top: 45px;
    right: 150px;
  }

  body[dir=rtl] .yellowStar {
    right: calc(100% - 150px)
  }
  
  .redStar {
    position: absolute;
    top: 200px;
    left: -100px;
  }
  
  .Partical1:after,
  .Partical1::before,
  .Partical2:after {
    position: absolute;
    width: 12px;
    height: 12px;
    content: "";
    border-radius: 50%;
  }
  
  .Partical1::before {
    top: -5px;
    right: -45px;
    background: #fff;
  }
  
  /* .Partical1:after {
      top: 48px;
      left: 200px;
      background: #EA4435;
  } */
  .Partical2:after {
    left: -67px;
    top: 11px;
    background: #FFB800;
  }
  
  .quikCallBanner h1 {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    text-transform: capitalize;
    margin-top: 25px;
  }
  
  .quikCallBanner h1 span {
    color: #EA4435;
  }
  
  .BackBtn button {
    padding: 0px;
    min-width: 50px;
  }
  
  .fillterDropDown .MuiSelect-select {
    font-size: 14px;
    padding: 8.1px 14px;
    font-weight: 500;
    text-transform: capitalize;
    color: #005DB8;
    min-width: 180px;
  }
  
  .Mui-focused.MuiInputBase-formControl .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
  }
  
  .fillterDropDown .MuiOutlinedInput-notchedOutline {
    border-color: #005DB8;
  }
  
  .fillterDropDown .Mui-selected.MuiMenuItem-root {
    background: #005DB8 !important;
    color: #fff;
  }
  
  .fillterDropDown .MuiSelect-iconOutlined {
    color: #005DB8;
    font-size: 26px !important;
  }
  
  .favouriteTutors .sliderBox {
    background: #fff;
    border-radius: 8px;
  }
  
  .favouriteTutors .sliderBox .contentBox {
    padding: 16px;
  }
  
  .favouriteTutors .sliderBox .imageBox {
    border-radius: 8px;
    position: relative;
    padding-bottom: 56.2%;
  }
  
  .favouriteTutors .sliderBox .imageBox img {
    width: 100%;
    object-fit: cover;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  
  .sliderBox h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-transform: capitalize;
  }
  
  .favouriteTutors .question h3 {
    margin-bottom: -27px;
  }
  
  .sliderBox .contentBox {
    padding: 10px;
  }
  
  .sliderBox .contentBox h4 {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: #000;
    margin: 2px 0px;
    justify-content: center;
  }
  
  .sliderBox .contentBox p {
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
  }
  
  .favouriteTutors .swiper-button-next,
  .favouriteTutors .swiper-button-prev {
    position: absolute;
    top: 15px;
    z-index: 9999;
  }
  
  .favouriteTutors .swiper-button-next {
    left: auto;
    right: 3px;
  }
  
  .favouriteTutors .swiper-button-prev {
    left: auto;
    right: 35px;
  }
  
  .favouriteTutors .swiper-button-prev:after, .favouriteTutors .swiper-rtl .swiper-button-next:after, .favouriteTutors .swiper-button-next:after, .favouriteTutors .swiper-rtl .swiper-button-prev:after {
    font-size: 12px;
    font-weight: 600;
    z-index: 9999;
    color: #000;
    background: #fff;
    min-width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
  }
  
  .favouriteTutors .swiper-button-prev:hover:after,
  .favouriteTutors .swiper-button-next:hover:after {
    background: #005DB8;
    color: #fff;
  }
  
  .favouriteTutors .swiper-pagination {
    position: absolute;
    bottom: 7px !important;
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-pagination .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: flex;
    border-radius: 50%;
    background: #fff;
    opacity: 1;
  }
  
  .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #005DB8;
  }

  .outerCard {
    border-radius: 16px;
    overflow: hidden;
  }
  
  .hireBtn {
    background: #e9e9e9;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #005DB8;
    text-transform: capitalize;
    padding: 1px 8px;
    /* transition: all 0.3s ease-in-out; */
    cursor: unset;
  }
  
  /* .hireBtn:hover{background:#005DB8; color: #fff;} */
  /* .timerBtn {
      background: rgba(256,256,256,0.4);
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      text-transform: capitalize;
      padding: 2px 8px;
  }
  
  .timerBtn:hover {background: rgba(256,256,256,1);color: #000;} */
  .timerBtn {
    background: #e9e9e9;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    color: #005DB8;
    text-transform: capitalize;
    padding: 1px 8px;
    cursor: unset;
  }
  
  /* .timerBtn:hover {background:#005DB8; color: #fff;} */
  .RatingBox .MuiRating-readOnly {
    font-size: 16px !important;
  }

  body[dir=rtl] .RatingBox .MuiRating-readOnly {
    transform: scaleX(-1);
  }
  
  .dateTimeBox span {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    color: #727272;
    margin-bottom: 5px;
  }
  
  .mathsBtn button {
    padding: 2px 8px 2px 8px;
    text-transform: capitalize;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-right: 10px;
    margin-left: 0px;
    margin-bottom: 5px;
  }

  body[dir=rtl] .mathsBtn button {
    margin-left: 10px;
    margin-right: 0px;
  }
  
  .videoSection h5 {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #000;
  }
  
  .outerCard .contentBox {
    background: #fff;
    padding: 16px;
  }

  .outerCard .contentBoxList {
    background: #fff;
    padding: 16px;
    height: 216px;
  }
  
  
  .sessionCard {
    background: #EAF4FF;
    padding: 12px;
  }

  .outerCard .VideoBox {
    border-bottom: 2px solid #EAF4FF;
  }
  
  .VideoBox {
    position: relative;
    min-height: 195px;
    background: antiquewhite;
    /* background: url('../../assets//images/videoimg.png') no-repeat center / cover; */
    /* border-bottom: 2px solid #EAF4FF; */
    background: rgba(0, 0, 0, 0.8);
  }
  .VideoBox > .react-thumbnail-generator {
    width: 100%;
    height: 100%;
  }
  .VideoBox > .react-thumbnail-generator > img {
    width: 100%;
    height: 100%;
  }

  .tutorRecentSession .VideoBox > .react-thumbnail-generator > img {
    border: 1px solid #005DB8;
    border-radius: 8px;
  }
  
  .VideoBox .VideoMsg {
    color: #fff;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    margin: 0 auto;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    height: 100%;
  }
  
  .playBtn {
    background: #EA4435;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .videoInBts {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 10px;
  }
  
  .subjectBtns {
    flex-wrap: wrap;
  }
  
  .subjectBtns button {
    border: 1px solid #005DB8;
    display: flex;
    padding: 2px 8px;
    border-radius: 4px;
    color: #005DB8;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
  }
  
  .subjectBtns button:hover, .subjectBtns button.selected {
    background: #005DB8;
    color: #fff;
  }
  
  .sessionBtn .blueBtn {
    padding: 2px 8px 2px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
    border: 1px solid #005DB8;
  }
  
  .sessionBtn .blueBtn:hover {
    background: #fff !important;
    border: 1px solid #005DB8;
    color: #005DB8;
  }
  
  /* .sessionBtn button.blueBtn:hover {
    background: #EA4435 !important;
    border: 1px solid #EA4435 !important;
    color: #fff;
  } */
  
  /* .sessionBtn .blueBtn:disabled {
    background-color: #F5F5F5 !important;
    color: #D3D3D3;
    border: 1px solid #F5F5F5;
    cursor: auto;
  } */

  .sessionBtn .connectStatus {
    padding: 2px 8px 2px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    text-transform: capitalize;
  }

  .sessionBtn .onlineStatus {
    color: #fff;
    background: #005DB8;
  }

  .sessionBtn .busyStatus {
    color: #fff;
    background: #EA4435;
  }

  .sessionBtn .offlineStatus {
    background-color: #F5F5F5 !important;
    color: #D3D3D3;
    cursor: auto;
  }
  
  .sessionBtn .blueBtn:hover svg path {
    stroke: #005DB8;
  }
  
  .sessionBtn .blueBtn svg {
    margin-right: 5px;
  }
  
  .borderBtn {
    padding: 2px 8px 2px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    color: #005DB8;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
    border: 1px solid #005DB8;
  }
  
  .borderBtn:hover {
    background: #005DB8 !important;
    color: #fff;
    /* border: 1px solid #005DB8;color:#005DB8; */
  }
  
  .RatingBox .cardLeft button {
    /* height: 22px; */
    font-size: 12px !important;
    /* padding: 0px 5px; */
    display: flex;
    align-items: center;
  }
  
  .MuiButtonBase-root.MuiButton-root.borderBtn {
    padding: 2px 8px 2px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    color: #005DB8;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
    border: 1px solid #005DB8;
  }
  
  .MuiButtonBase-root.MuiButton-root.borderBtn:hover {
    background: #005DB8 !important;
    color: #fff;
    /* border: 1px solid #005DB8;color:#005DB8; */
  }
  
  body[dir=rtl] .sessionBtn .blueBtn svg {
    margin-left: 5px;
    margin-right: 0;
  }
  
  .sessionCard h5 {
    text-transform: capitalize;
  }
  
  .TeachInfo h5 {
    color: #555;
  }
  .TeachInfo .RatingBox .cardLeft button {
    background: #EAF4FF !important;
  }
  
  ul.MuiPagination-ul li button {
    color: #555;
    font-weight: 500;
    font-size: 14px;
    border: 1px solid #555;
  }

  body[dir=rtl] ul.MuiPagination-ul li button svg {
    transform: scaleX(-1)
  }
  
  ul.MuiPagination-ul li button:hover,
  ul.MuiPagination-ul li button.Mui-selected {
    background: #005DB8;
    color: #fff;
    border: 1px solid #005DB8;
  }
  
  .AdverBanner {
    background-image: url(../../assets/images/addBg.png);
    width: 100%;
    height: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 30px 15px 30px 40px;
    border-radius: 12px;
  }
  
  .AdverBanner h2 {
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    text-transform: capitalize;
  }
  
  .AdverBanner .contentBox {
    max-width: 300px;
    width: 100%;
  }
  
  /* Recent Sessions */
  .questionMain {
    padding: 16px;
    border-radius: 16px;
    background: #fff;
  }
  
  .question h3 {
    font-size: 20px;
    font-weight: 600;
    color: #0F4D92;
    text-transform: capitalize;
  }
  
  .subjectSelect h3 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
    line-height: 24px;
  }
  
  .uploadImg {
    position: relative;
    background: #f5f5f5;
    border-radius: 8px;
  }
  
  .uploadImg label {
    background: #F5F5F5 !important;
    width: 100%;
    min-height: 100px;
    box-shadow: none;
    border-radius: 8px;
  }
  
  .uploadcontent p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #727272;
    text-transform: capitalize;
  }
  
  .uploadcontent span {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
  
  .uploadcontent {
    max-width: 220px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
  }
  
  .uploadImg:hover .uploadcontent p {
    color: #0F4D92;
  }
  
  .uploadImg:hover .uploadcontent span svg path {
    stroke: #0F4D92;
  }
  
  .orBox {
    position: relative;
    margin: 25px 0px;
    display: flex;
  }
  
  .orBox:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 1px;
    background: #F5F5F5;
  }
  
  .orBox span {
    color: #0F4D92;
    font-size: 20px;
    font-weight: 500;
    display: inline-block;
    width: 50px;
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 1;
  }
  
  .questionMessage textarea {
    background: #F5F5F5;
    border: 1px solid #F5F5F5;
    width: 100%;
    min-height: 100px;
    border-radius: 8px;
    resize: none;
    outline: none;
    padding: 15px;
    transition: all 0.3s ease-in-out;
  }
  
  .questionMessage textarea:focus-visible {
    border: 1px solid #0F4D92;
    background: #fff;
  }
  
  .divider {
    background: #F5F5F5;
    height: 1px;
    width: 100%;
    margin: 15px 0px;
  }
  
  .selectBtn {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .selectBtn button {
    font-size: 14px;
    font-weight: 500;
    color: #555;
    border: 1px solid #555;
    border-radius: 8px;
    padding: 4px 10px;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
  }
  .selectBtn button.cis-selected, .selectBtn button:hover {
    background-color: #005DB8;
    border: 1px solid #005DB8;
    color: #fff;
  }
  
  /* .selectBtn button:hover {
      color: #005DB8;
      border: 1px solid #005DB8;
  } */
  .subjectSelect {
    margin-bottom: 20px;
  }
  
  .subjectSelect:last-child {
    margin-bottom: 0;
  }
  
  .connectBtn {
    background: #EA4435;
    color: #fff;
    border: 1px solid #EA4435;
    border-radius: 8px;
    padding: 6px 12px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
  }
  
  .connectBtn:hover {
    background: #005DB8;
    color: #fff;
    border: 1px solid #005DB8;
  }
  
  .RecentSessionDetails .contentBox h5 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    text-transform: unset;
  }
  
  .noteBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
  }
  
  .ProfilePic {
    background: #F5F5F5;
    min-width: 34px;
    max-width: 34px;
    height: 34px;
    display: flex;
    width: 32px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    position: relative;
    /* cursor: pointer; */
  }

  .ProfilePic:after {
    width: 8px;
    height: 8px;
    /* background: #A0F533; */
    position: absolute;
    content: "";
    right: -3px;
    top: 20px;
    border-radius: 50%;
  }
  
  .ProfilePic.add-offline:after {
    background: #808080a6;
    cursor: pointer;
  }

  .ProfilePic.add-busy:after {
    background: #EA4435;;
    cursor: pointer;
  }

  .ProfilePic.add-online:after {
    background: #A0F533;
    cursor: pointer;
  }
  
  .ProfilePic > img {
    min-width: 34px;
    max-width: 34px;
    height: 34px;
    border-radius: 50%;
  }
  
  .noteBox .contentBox p {
    color: #555;
    font-size: 14px;
    font-weight: 600;
  }
  
  .noteBox .contentBox p span {
    /* color: #0F4D92; */
  }
  
  .availableBtn button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 8px 12px 8px 12px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
  }
  
  .availableBtn button.darkBlue {
    color: #fff;
  }
  
  .availableBtn button.darkBlue svg {
    margin-right: 5px;
  }
  
  .availableBtn button:disabled {
    cursor: unset;
  }
  
  body[dir=rtl] .availableBtn button.darkBlue svg {
    margin-right: 0px;
    margin-left: 5px;
  }

  .outerCard .TutorAvailable.MuiBox-root {
    border: 1px solid #727272b0;
    border-radius: 8px;
    padding: 10px 10px;
  }

  .outerCard .TutorAvailable:not(:last-child) {
    margin-bottom: 16px; /* Adjust the margin value as per your design */
  }
  
  .tutorAvailableIn {
    position: relative;
  }

  .tutorAvailableIn .imageBox {
    position: relative;
  }
  .tutorAvailableIn .imageBox > img {
    max-height: 158px;
    max-width: 190px;
    height: 100%;
    border-radius: 8px;
  }
  .tutorAvailableIn .imageBox::after {
    position: absolute;
    top: 10px;
    right: 10px;
    content: "";
    background: #808080a6;
    border-radius: 50%;
    width: 8px;
    height: 8px;
  }
  .tutorAvailableIn .imageBox.removeCircle::after {
    display: none;
  }
  .tutorAvailableIn .imageBox.greenCircle::after {
    background: #A0F533;
  }
  .tutorAvailableIn .imageBox.redCircle .tutorAvailableIn .imageBox::after {
    background: #EA4435;
  }
  
  .tutorAvailableIn .sessionBox {
    display: block;
    width: 100%;
  }
  
  .tutorAvailableIn .sessionBox .contentBox {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  
  .tutorAvailableIn .sessionBtn button {
    display: flex;
    align-items: center;
    border: 1px solid #005DB8;
    padding: 8px 12px;
    color: #005DB8;
    font-weight: 600;
    text-transform: capitalize;
    transition: all 0.3s ease-in-out;
  }
  
  .tutorAvailableIn .sessionBtn button:hover {
    background: #005DB8;
    color: #fff;
  }
  
  .tutorAvailableIn .sessionBtn button svg {
    margin-right: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  body[dir=rtl] .tutorAvailableIn .sessionBtn button svg {
    margin-right: 0px;
    margin-left: 5px;
  }
  
  .tutorAvailableIn .sessionBtn button:hover svg path {
    stroke: #fff;
  }
  
  .content-in h3 span.greenCircle {
    position: relative;
    font-size: 14px;
    color: #000;
    margin-left: 25px;
  }
  
  .content-in h3 span.greenCircle:after {
    position: absolute;
    top: 5px;
    left: -10px;
    content: "";
    background: #A0F533;
    border-radius: 50%;
    width: 6px;
    height: 6px;
  }
  
  .content-in h3 span.redCircle {
    position: relative;
    font-size: 14px;
    color: #000;
    margin-left: 25px;
  }
  
  /* .content-in h3 span.redCircle:after {
      position: absolute;
      top: 5px;
      left: -10px;
      content: "";
      background: #EA4435;
      border-radius: 50%;
      width: 6px;
      height: 6px;
  } */
  .PersonalDetails h4 {
    display: flex;
  }
  
  .PersonalDetails .contentBox h5 {
    font-size: 18px;
    font-weight: 600;
  }
  
  .underLineBtn {
    background: #EAF4FF;
    color: #005DB8;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px 8px 0 0;
    padding: 8px 12px;
    text-transform: capitalize;
    cursor: pointer;
    border: 1.5px solid #005DB8;
    border-bottom: none;
  }
  
  .underLineBtn:hover {
    background: #005DB8;
    color: #fff;
  }
  
  .underLineBtn-Selected {
    background: #005DB8;
    color: #fff;
  }
  
  .blueline {
    border-bottom: 1px solid #005DB8;
  }
  
  .tutorRecentSession .outerCard {
    margin-bottom: 15px;
    min-height: auto;
  }
  
  .tutorRecentSession .outerCard:last-child {
    margin-bottom: 0px;
  }
  
  .tutorRecentSession .outerCard {
    display: flex;
    background: #fff;
    padding: 15px;
  }
  
  .tutorRecentSession .VideoBox {
    max-width: 240px;
    width: 100%;
    min-height: 150px;
    height: 150px;
    border-radius: 8px;
  }
  
  .tutorRecentSession .outerCard .contentBox {
    background: #fff;
    padding: 0px 16px;
    width: calc(100% - 240px);
  }
  
  .tutorRecentSession .outerCard .contentBox .TimeRankBox .RatingBox {
    margin-top: -22px;
    min-width: 120px
  }
  
  .tutorRecentSession .outerCard .contentBox h5 {
    /* font-size: 14px; */
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    line-height: 20px;
  }
  
  .RecentSessionDetails .outerCard {
    background: #fff;
    padding: 16px;
  }
  
  .RecentSessionDetails .outerCard .VideoBox {
    border-radius: 16px;
  }
  
  .RecentSessionDetails .outerCard .VideoBox .playBtn {
    width: 38px;
    height: 38px;
  }
  
  .RecentSessionDetails .outerCard .contentBox {
    background: #fff;
    padding: 16px 0px;
  }
  
  .RecentSessionDetails .sessionCard h5 {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  
  .BookMarkReport button {
    display: flex;
    align-items: center;
  }
  
  .BookMarkReport button svg {
    margin-right: 5px;
  }
  
  body[dir=rtl] .BookMarkReport button svg {
    margin-right: 0px;
    margin-left: 5px;
  }
  
  .TabsMain .tabsBoxTop {
    background: transparent;
    border-bottom: 1px solid #1976d2;
    padding: 16px 0;
    padding-bottom: 0;
  }

  .TabsMain .tabsBoxTop .MuiTabs-root { 
    min-height: 0px !important;
  }
  
  .TabsMain .outerCard .VideoBox {
    background: #fff;
  }
  
  .TabsMain button.Mui-selected,
  .TabsMain button.Mui-selected, .TabsMain .css-1aquho2-MuiTabs-indicator, .TabsMain button {
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #1976d2;
    border-bottom: 0;
    color: #1976d2 !important;
  }
  
  .TabsMain button.Mui-selected {
    color: #fff !important;
    border: 1px solid #1976d2;
    background-color: #1976d2;
    border-bottom: 0px;
  }

  
  .SearchMain .search-in {
    position: relative;
    width: 100%;
  }
  
  .SearchMain .inputBox {
    border: 1px solid #727272;
    height: 44px;
    border-radius: 4px;
    padding: 5px 15px 5px 50px;
    text-transform: capitalize;
    font-size: 14px;
    color: #727272;
    font-weight: 500;
    width: 100%;
    outline: none;
  }
  
  .SearchMain .inputBox:focus {
    border: 1px solid #1976d2;
  }
  
  .SearchMain .serch-icon {
    position: absolute;
    left: 15px;
    top: 10px;
  }
  
  [dir=rtl] .SearchMain .serch-icon {
    left: unset;
    right: 15px;
  }
  [dir=rtl] .SearchMain .inputBox {
    padding: 5px 50px 5px 15px;
  }
  
  .SearchMain .fillterDropDown .MuiOutlinedInput-notchedOutline {
    border-color: #727272;
  }
  
  .SearchMain .fillterDropDown .MuiSelect-select {
    color: #727272;
  }

  .progress {
    /* position: absolute; */
    width: 20px !important;
    height: 20px !important;
    margin-left: 10px;
    margin-right: 0px
  }

  .sub-progress {
    position: relative;
    top: 3px;
  }

  body[dir=rtl] .progress {
    margin-left: 0px;
    margin-right: 10px
  }


  .SearchMain .fillterDropDown .MuiSelect-iconOutlined {
    color: #727272;
    font-size: 26px !important;
  }
  
  .SearchMain p {
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  
  .TutorListOnline {
    width: 100%;
  }
  
  .TutorListMain h3 {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0px;
  }
  
  .onlineProfile .imageBox {
    margin-right: 10px;
  }

  .onlineProfile .imageBox span {
    background: #F5F5F5;
    min-width: 34px;
    max-width: 34px;
    height: 34px;
    display: flex;
    width: 32px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    position: relative;
    >img {
      min-width: 34px;
      max-width: 34px;
      height: 34px;
      border-radius: 50%;
    }
  }
  
  body[dir=rtl] .onlineProfile .imageBox {
    margin-right: 0;
    margin-left: 15px;
  }
  
  .onlineProfile .imageBox span {
    display: flex;
    margin-left: -11px;
  }
  
  .TutorListMain .outerCard {
    background: #fff;
    padding: 16px;
    margin-bottom: 15px;
  }
  
  .TutorListMain .tutorAvailableIn .imageBox {
    position: relative;
    max-width: 112px;
    max-height: 94px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }
  .TutorListMain .tutorAvailableIn .imageBox > img {
    /* max-width: 112px;
    max-height: 94px; */
    width: 100%;
    object-fit: cover;
  }
  
  .TutorListMain .tutorAvailableIn .contentBox {
    padding: 0px;
  }
  
  .bdrbtn {
    border: 1px solid #005DB8;
    color: #005DB8;
  }
  
  .TutorListMain .availableBtn button {
    padding: 2px 12px 2px 12px;
    height: 32px;
    min-width: 200px;
  }
  
  .TutorListMain .bdrbtn {
    border: 1px solid #005DB8;
    color: #005DB8;
    transition: all 0.3s ease-in-out;
  }
  
  /* .TutorAvailable .availableBtn button.darkBlue:hover{background: #fff; border: 1px solid #005DB8;
      color: #005DB8;} */
  .TutorAvailable .availableBtn button.bdrbtn:hover {
    background: #005DB8;
    border: 1px solid #005DB8;
    color: #fff;
  }
  
  .TutorAvailable .progressNumber > span {
    border-radius: 4px;
  }
  
  .TutorAvailable .progressNumber span span {
    padding: 0px 5px;
    align-items: center;
  }
  
  .TutorListMain .contentBox h3 {
    color: #0F4D92;
    gap: 5px;
  }
  
  .connectPoint:after,
  .offlinePoint:after,
  .busynowPoint:after {
    height: 8px;
    width: 8px;
    top: 8px;
    right: 8px;
    border-radius: 50%;
    content: "";
    z-index: 9;
    display: flex;
    position: absolute;
  }
  
  .connectPoint:after {
    background: #A0F533 !important;
  }
  
  .offlinePoint:after {
    background: #F5F5F5;
  }
  
  .busynowPoint:after {
    background: #EA4435;
  }
  
  .offlinebtn {
    background: #F5F5F5;
    color: #727272;
    min-width: 100px;
  }
  
  .compeleteBtn {
    background: #91e291;
    color: #fff;
  }

  .busynowBtn {
    background: #EA4435;
    color: #fff;
  }
  
  .TutorListMain .availableBtn button.busynowBtn {
    color: #fff;
  }
  
  .viewMoreBtn {
    background: #fff;
    color: #005DB8;
    border: 1px solid #005DB8;
    border-radius: 16px;
    height: 34px;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 500;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    min-width: 175px;
    margin: 0;
    justify-content: center;
    line-height: normal;
    transition: all 0.3 ease-in-out;
  }
  
  .viewMoreBtn:hover {
    background: #005DB8;
    color: #fff;
  }
  
  .imgCrossWrap {
    position: relative;
    max-width: 600px;
  }
  .imgCrossWrap .remove-button {
    position: absolute;
    top: -12px;
    right: -21px;
    z-index: 1;
  }
  .imgCrossWrap .remove-button > img {
    width: 20px;
    height: 20px;
  }
  
  [dir=rtl] .imgCrossWrap .remove-button {
    position: absolute;
    right: unset;
    left: -21px;
  }

  body[dir=rtl] .favrouiteBtn {
    margin-left: 0;
    margin-right: auto;
  }
  
  .favrouiteBtn.active > svg circle {
    fill: #EA4435;
  }
  
  .moneyFont {
    font-size: 18px;
  }
  
  .sessionBtn .blueBtn:disabled:hover {
    pointer-events: none;
    background-color: #F5F5F5 !important;
    color: #D3D3D3;
    border: 1px solid #F5F5F5 !important;
  }
  
  .contentInFavrouite {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  
  .redTxt {
    color: #ea4435 !important;
  }