@media(max-width:1199px) {

    .containerShape::after,
    .containerShape::before{display: none;}
    .Partical1::before {
        top: -5px;
        right: 15px;
    }
    .Partical2:after {
        left: 50px;
        top: -32px;
    }
    .redStar {
        top: 50px;
        left: 0px;
    }
}

@media(max-width:900px) {
    .menuBar button {
        padding: 0px;
    }

    .headerManu button {
        font-size: 24px;
    }

    .heroSection .MuiTypography-h2 {
        font-size: 32px;
        line-height: 40px;
    }

    .heroSection p {
        font-size: 16px !important;
        line-height: 24px;
    }

    .heroIn {
        padding: 0px 15px 175px 15px;
    }

    .cardList ul li {
        font-size: 18px;
    }

    .OuterContainer {
        border: none;
        margin-bottom: 30px;
    }

    .studyContainer .studyBanner {
        display: block;
        margin: 0px 15px;
    }

    .subHeading {
        margin: 0px 15px;
    }

    .headerMain .css-2bi4m2-MuiContainer-root {
        padding-left: 15px;
        padding-right: 15px;
    }

    .mobMenu .MuiMenu-paper {
        border-radius: 0;
    }

    .web-logo img {
        max-width: 100px;
        width: 100%;
    }

    .practiceBox {
        padding: 0px 15px 40px 15px;
    }
}

     @media(max-width:767px) {
        .Partical2:after {
            left: 12%;
            top: -17px;
        }


    .mobMenu ul { margin: 0px; padding: 0; }
    .mobMenu ul li { min-height: auto; gap: 5px;}
    .heroSection { min-height: 380px; }
    .heroSection .banner-image { display: none; }
    .heroSection::after { max-width: 150px; height: 170px; bottom: -1px; }
    .heroSection::before { width: 305px; height: 180px; }
    .OuterContainer { margin: -80px auto; margin-bottom: 0px; }
    .heroSection .MuiTypography-h2 { font-size: 24px; line-height: 30px; }
    .heroSection p { display: none; }
    .studyBanner .imageBox { margin-top: 15px; }
    .studyContainer h3 { font-size: 20px; }
    .studyContainer p { font-size: 16px; }
    .studyBanner { padding: 20px 20px 0px 20px; }
    .practiceBox .cardTop,  .practiceBox .cardRight { display: block; }
    .cardList ul li { font-size: 16px; margin: 0px 7px; }
    .cardIcon { margin-right: 0; margin-right: 10px;}
    body[dir="rtl"] .cardIcon { margin-left: 10px;}
    .solutionBanner::after{display: none;}
    
    .cardList ul { margin: 5px 0px; }
    .cardList ul li:after { left: -8px; }
    .progressNumber { display: block; }
    .progressNumber span { margin: 7px 0px; padding: 5px 18px; max-width: fit-content; width: 100%; }
    .cardContent h4 { font-size: 20px; }

    .contactUs {
        gap: 10px;
    }

    .contactUs a {
        font-size: 18px;
    }

    .socialBox a {
        width: 35px;
        height: 35px;
    }

    .subjectPg .chaptersBox {
        display: block;
    }

    .subjectPg .chaptersBox .chapters {
        margin: 15px 0px;
        max-width: 100%;
    }

    .SolutionBox .cardLeft {
        justify-content: start;
    }

    .TabsBox button {
        min-height: 38px;
        padding: 8px 5px;
        min-width: 80px;
        margin-right: 7px;
    }

    .practiceBox .questionType .cardRight,
    .practiceBox .questionType .cardTop,
    .practiceBox .QuestionSolution .cardRight {
        display: flex;
    }

    .chapterBoxIn {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        padding: 10px 15px;
    }

    .chaptersBox,
    .chapterBoxIn {
        display: block;
    }

    .practiceBox .QuestionSolution .cardLeft {
        width: auto;
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
    }

    .createTeachers .chaptersBox {
        display: block;
    }

    .createTeachers .chapterBoxIn .iconsBox img {
        width: 35px;
    }

    .createTeachers .chapterBoxIn {
        padding-left: 45px;
    }

    body[dir="rtl"] .createTeachers .chapterBoxIn {
        padding-right: 45px;
    }

    .continueBtn {
        font-size: 18px;
        padding: 5px 15px;
        max-width: 205px;
    }

    .continueBtn span svg {
        width: 8px;
    }

    .MockTestContent .continueBtn span svg {
        width: auto;
    }

    .detailFull ul li {
        margin: 0;
        width: 100%;
        justify-content: flex-start;
    }

    .detailFull ul li:after {
        display: none;
    }

    .MockTestContent .continueBtn {
        font-size: 18px;
        padding: 7px 7px;
    }

    .MockTestContent .continueBox {
        gap: 10px
    }

    .MockTestContent .continueBtn span {
        margin: 0px 3px;
        font-size: 14px;
    }

    .MockTestContent .InnerBorder .cardLeft {
        max-width: max-content;
    }

    body[dir="rtl"] .detailFull ul li:first-child::after {
        display: none;
    }

    /* quick banner */
    .quikCallBanner {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: -240px;
    }

    .quikCallBanner h1 {
        font-size: 24px;
        margin-top: 0px;
    }

    .filterhead,
    .fillterDropDown {
        display: block !important;
    }

    .fillterDropDown .MuiFormControl-root {
        margin-bottom: 7px;
    }

    .AdverBanner {
        padding: 15px;
        display: block;
    }

    .VideoBox img {
        width: 100%;
    }

    .practiceBox .cardLeft {
        justify-content: flex-start;
    }


    .quikCallBanner h1 {font-size: 24px;}
    .OuterContainer.containerShape{padding: 15px;}
    .quikCallBanner {padding-top: 95px; }
    .questionMain .subjectSelect .selectBtn {flex-wrap: wrap;}
    .questionMain .subjectSelect .selectBtn button {padding: 2px 8px;}
    .filterBox{margin:20px 0px 20px 0px !important;}
    .tutorAvailableIn .sessionBox  .contentBox {flex-wrap: wrap;}
    .TutorAvailable .sessionBtn {margin-top: 5px;}
    .tutorAvailableIn{flex-wrap: wrap;}
    /* .PersonalDetailsBox { gap: 8px; } */
    .PersonalDetailsBox div {margin: 15px 0px;}
    .tutorRecentSession .outerCard {display: block;}
    .tutorRecentSession .outerCard .contentBox {padding: 16px 0px;}
    .tutorRecentSession .VideoBox {max-width: 100%;width: 100%;height: auto;}
    .RatingBox .cardLeft button {padding: 2px 10px;}
    .AdverBanner{padding: 15px;}
    .AdverBanner h2 {font-size: 18px;}

    .TutorListMain .progressNumber {
        display: flex;
        flex-wrap: wrap;
    }
    .TutorAvailable .progressNumber > span {
        padding: 7px 12px; margin: 0px;
    }
    .TutorAvailable .progressNumber span span {
        margin: 0;
    }
    .TutorAvailable .RatingBox {
        margin-bottom: 10px;
    }

    .PersonalDetails .PersonalDetailsBox {
        display: block;
    }
    .tutorMain  .tutorRecentSession .outerCard {
        display: block;
    }
    .tutorMain  .tutorRecentSession .outerCard .contentBox {
        padding: 16px 0px;
    }

    .tutorMain .tutorRecentSession .VideoBox {
        max-width: 100%;
        width: 100%;
        height: auto;
    }
    .RecentSessionDetails .sessionCard .innerBox {
        display: block;
    }
    .RecentSessionDetails .sessionCard .innerBox .availableBtn {margin-top: 5px;}
    .RecentSessionDetails .sessionCard .innerBox.availableBtn button {
        padding: 3px 8px 3px 8px;
    }

    }

     @media(max-width:480px) {
    .heroSection::after { max-width: 155px; height: 160px; bottom: -2px; }
    .heroSection::before { width: 269px; height: 162px; }
    .subjectBtns button {padding: 2px 5px;}
    .AdverBanner {padding: 15px;}
    .tutorMain .tutorAvailableIn .contentBox {display: block;}
    }
     @media(max-width:375px) {
    .heroSection { min-height: 325px; }
    .RankInfo{display: block;}
    .TeachInfo {margin-top: 5px;}
    .VideoBox img {
        width: 100%;
        min-height: 150px;
    }

    .subjectBtns {
        flex-wrap: wrap;
    }

    .TimeRankBox {
        display: block;
    }

}