.headerMain { background: #0F4D92 !important; box-shadow: none !important; border: none; outline: none; padding: 20px 0px 20px 0px; }
.headerManu button { display: flex; align-items: center; font-weight: 500; line-height: 30px; letter-spacing: 0.01em; font-size: 18px; text-transform: capitalize; padding: 0px; margin: 0px 15px; }
.headerManu button a { margin-right: 5px; transition: all 0.3s ease-in-out; }
.headerManu button a svg path { transition: all 0.3s ease-in-out; }
.headerManu button:hover { color: #EA4435; }
.headerManu button:hover a svg path { fill: #EA4435; }
.headerManu .activeMenu { color: #EA4435; }
.headerManu .activeMenu a svg path { fill: #EA4435; }
.web-logo { width: 100px; }
.userProfile a { margin-left: 5px; }
.userProfile button .MuiAvatar-circular { width: 36px; height: 36px; background: #EA4435; }
body[dir="rtl"] .userProfile button, body[dir="rtl"] .headerManu button { flex-direction: row-reverse; }
body[dir="rtl"] .iconCircle { margin-left: 8px; }
.profileDropDown .MuiMenu-paper { border-radius: 0px; box-shadow: 0px 10px 16px 0px #0B3D742E; }
.profileDropDown .MuiMenu-paper ul { padding: 0px; }
.profileDropDown .MuiMenu-paper ul li { display: flex; flex-wrap: wrap; flex-direction: column; padding: 0px; }
.profileDropDown .MuiMenu-paper ul li a { font-Weight: 500; font-size:14px; line-height: 20px; color: #0F4D92; text-transform: capitalize; display: flex; justify-content: flex-start; flex: 1 1; width: 100%; align-items: center; text-decoration: none; padding: 8px 15px; }
.profileDropDown .MuiMenu-paper ul li a:hover { color: #fff; background: #EA4435; }
.iconCircle { width: 24px; height: 24px; background: #0F4D92; color: #fff; display: flex; border-radius: 50%; justify-content: center; align-items: center; margin-right: 8px; }
.profileDropDown .MuiMenu-paper ul li a:hover .iconCircle svg path { fill: #EA4435; }
.profileDropDown .MuiMenu-paper ul li a:hover .iconCircle { background: #fff; }
.mobMenu ul { margin: 0px; }
.mobMenu ul li:hover { background: #EA4435; }
.mobMenu ul li:hover p { color: #fff; }
.mobMenu .activeMobMenu { color: #EA4435; }
.mobMenu ul li p { text-transform: capitalize; font-size: 14px; }
.languageBox { display: flex; align-items: center; justify-content: center; background: #F5F5F5; border-radius: 35px; margin: 10px 0px; gap: 1px; padding: 0; }
.languageBox span { display: flex; justify-content: center; align-items: center; font-size: 14px; padding: 5px 8px; }
.languageBox .activeLanguage { background: #0F4D92; color: #fff; border-radius: 35px; }
.languageBox span img { margin-right: 4px; margin-left: 0px; }
body[dir=rtl] .languageBox span img { margin-right: 0px; margin-left: 4px; }
.headerMain .MuiSvgIcon-fontSizeMedium {
    font-size:28px !important;
    color: #fff;
}

 @media (min-width: 900px) {
.headerManu { display: flex; justify-content: end; }
}
 @media (max-width:899px) {
.userProfile{margin:0px 15px;}
.menuBar{order: 1;flex-grow: inherit !important;}
.web-logo{margin-right:auto; flex: 1;}
}
