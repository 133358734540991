
.subHeading .cardMain {
    border: none;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-bottom:15px;
    min-height: 130px;
}
.subHeading .backBtn {
    font-size: 14px;
    line-height: 25px;
    font-weight: 500;
    color: #0F4D92;
    text-transform: capitalize;
  }
.backBtn span{
    margin-right: 4px;
    margin-left: -4px;
}
.backBtn span svg {
    font-size: 14px !important;
}
.subHeading .cardIcon {background: #E6EFF8;}

body[dir="rtl"] .backBtn span{
    margin-left: 4px;
    margin-right: -4px;
}

.mock-test-timer {
    display: inline-flex;
    align-items: center;
    gap: 15px;
    font-size: 25px;
    color: #EA4435;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
}
.mock-test-timer .stop-timer {
    width: 18px;
    height: 18px;
    background: #EA4435;
    border-radius: 50%;
    position: relative;
    top: -1px;
}
.mock-test-timer>span {
    line-height: 1;
}
.subHeading .cardMain {
    position: relative;
}