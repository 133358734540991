.tab-wrapper button.MuiTab-root {
    border: 1px solid #005DB8;
    border-bottom: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 14px;
    padding: 8px;
    min-height: 30px;
}

.tab-wrapper .MuiTabs-flexContainer {
    gap: 8px;
    border-bottom: 1px solid #005db8;
}

.tab-wrapper .css-1ujnqem-MuiTabs-root {
    min-height: 30px;
}

.tab-wrapper button.MuiTab-root.Mui-selected {
    background-color: #005DB8;
    color: #fff;
}

.custom-panel-wrapper {
    background-color: #fff;
}


.performance-box h5 {
    color: #000;
}

.custom-panel-wrapper>div>.MuiBox-root {
    padding: 16px;
}

.custom-panel-wrapper .oc_headwrap {
    margin-bottom: 16px
}

.p-pbox {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 18px;
}

.p1-pbox,
.p2-pbox {
    width: 50%;
    min-height: 176px;
}

.p1-pbox {
    padding: 15px;
    background-color: #EAF4FF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.p2-pbox-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.p2-child-inner{
    display: flex;
    align-items: center;
    gap: 8px;
}
.p2-pbox{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: 16px
}
.p2-pbox>div{
    padding: 16px;
    min-height: 80px;
}
.p2-pbox>div:first-child{
    background-color: #FFF6DF;
}
.p2-pbox>div:nth-child(2){
    background-color: #DAFDFF;
}
.TeachInfo>h5{
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #000;
}
.css-1aquho2-MuiTabs-indicator{
    display: none;
}
.SectionIn .AdverBanner{
    margin-top: 16px;
}
.progressNumber-parent .progressNumber > span {
    border-radius: 4px;
} 
.progressNumber-parent .progressNumber span span{
    padding: 0px 5px;
}
.progressNumber-parent .progressNumber{
justify-content: flex-end;
margin-bottom: 16px;
}
.cyan{
    background-color: #DAFDFF;
}
.lt_purple{
    background-color: #800080;
    color: #f5f5f5 ;
}
.mainHeading>h4{
    color: #000;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0px 0 16px;
}
.subHeading>h3{
    color: #000;
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0px 0 16px;
}
.acc-label{
    display: flex;
    align-items: center;
    gap: 12px;
}
.css-i4bv87-MuiSvgIcon-root{
    /* fill: #005DB8 !important; */
}
.acc_wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
}
.box-level{
    display: inline-flex;
    align-items: center;
    gap: 16px;
}
.lt-green{
    background-color: #CCFED9;
    color: #000000;
}
.lt-red{
    background-color: #FCEEEE;
    color: #EA4435;
}
.box-level>span{
    padding: 5px 12px;
    font-weight: 500;
    text-transform: capitalize;
    border-radius: 4px;
}
.MuiAccordion-rounded{
    border-radius: 16px !important;
}
.acc_status{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 16px;
}
.acc_status>div {
    width: 33.33%;
    border-radius: 8px;
    color: #555555;
    font-weight: 500;
    border: 1px solid #f5f5f5;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.c-red{
    color: #EA4435;
}
.c-blue{
    color: #005DB8;
}
button.darkBlue{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 8px 12px 8px 12px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    background-color: #005DB8;
    color: #fff;
}
.MuiAccordionDetails-root .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 8px 12px 8px 12px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
    background-color: #005DB8;
    color: #fff;
    min-width: 160px;
    height: 35px;
    font-size: 18px;
}
.MuiAccordionDetails-root .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
    background-color: #fff;
    border: 1px solid #005DB8;
    color: #005DB8;
}
.viewMoreBtn{
    border-radius: 4px;
    min-width: 140px;
}
.css-1086bdv-MuiPaper-root-MuiAccordion-root.Mui-expanded{
    border: 1px solid #555555 !important;
}
.Classification{
    margin-top: 30px;
}
.rank_msg_wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EAF4FF;
    margin-top: 16px;
    padding: 16px;
    gap: 10px;
    border-radius: 8px;
}
.custom-panel-wrapper .rank_msg_wrap .RankInfo{
    background-color: transparent;
    margin-top: 0;
    padding: 0;
    border-radius: none;
}
.custom-panel-wrapper .rank_msg_wrap button.darkBlue{
    background-color: #005DB8;
    color: #fff;
    border-radius: 4px;
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    min-width: 140px;
    height: 35px;
}
.custom-panel-wrapper .rank_msg_wrap button.darkBlue:hover{
    background-color: transparent;
    color: #005DB8;
    border: 1px solid #005DB8;
}
.custom-panel-wrapper .white-card{
    padding: 16px;
    gap: 20px;
}
.custom-panel-wrapper .white-card:not(:last-child){
    margin-bottom: 16px;
}
.lbl_btn_flx{
    display: flex;
    align-items: center;
    gap: 15px;
}
.lbl_tag{
    font-size: 14px;
    font-weight: 500;
    padding: 4px 8px;
    min-width: 80px;
    height: 28px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
}
.lt_yellow{
    background-color: #FFF6DF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.dflx{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.boxes-wrapper{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 20px;
    width: 100%;
}
.box_wrp{
    padding: 8px 16px;
    width: 33.33%;
    background-color: #EAF4FF;
    border-radius: 8px;
    min-height: 85px;
}
.ass_box{
    background-color: #FFEBCE;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 16px;
}
.ass_left{
    display: flex;
    align-items: center;
    gap: 8px;
}
.ass_right{
    display: flex;
    align-items: center;
    gap: 24px;
}
.ps_box{
    background-color: #fff;
    border-radius: 4px;
    padding: 4px 12px;
}
.lt_red{
    color: #EA4435;
    background-color: #FFDBE1;
}
.custom-panel-wrapper>div>.MuiBox-root .white-card{
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid #D0DFF0;
}
.document_wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.docImgSample{
    display: flex;
    align-items: center;
    gap: 16px;
}
.docImg_wrap{
    display: flex;
    align-items: center;
    gap: 16px;
}
.docImg_wrap>img{
    /* min-width: 32px; */
    max-width: 40px;
    width: 100%;
}
.doc_time{
    display: flex;
    align-items: center;
    gap: 16px;
}
.doc_time>div{
    display: flex;
    align-items: center;
    gap: 4px;
}
.doc_time>div>p{
    font-size: 14px;
    line-height: 1;
    font-weight: 500;
    color: #555555;
}
.right_doc{
    cursor: pointer;
}
.input-textarea{
    max-width:89%;
}
.chat_wrapper{
    background-color: #ffffff;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    height: 465px;
    min-width: 576px;
}
.chat_left{
    padding: 16px;
    background-color: #EAF4FF;
    width: 260px;
    border-radius: 4px;
}
.chat_right{
    width: 100%;
    height: 100%;
    position: relative;
}
.read_label{
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
}
.read_label span{
    background-color: #EAF4FF;
    padding: 2px 8px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    border: 1px solid #D0DFF0;
    border-radius: 4px;
    min-width: 33px;
    text-transform: capitalize;
    cursor: pointer;
}
.read_label span.active, .read_label span:hover{
    background-color: #005DB8;
    border-color: #005DB8;
    color: #fff;
}
.msg_box{
    background-color: #ffffff;
    border-radius: 8px;
    padding: 8px;
    display: flex;
    gap: 8px;
    position: relative;
}
.chat_left{
    max-height: 470px;
}
.chat_left .msg_box:not(:last-child){
    margin-bottom: 8px;
}
.msg_box>div{
    padding-right: 20px;
}
.msg_box>img{
    min-width: 38px;
    max-width: 38px;
    min-height: 38px;
    max-height: 38px;
}
.msg_box_wrapper{
    overflow: auto;
    max-height: 290px;
}
.chat_left .MuiButtonBase-root .MuiButtonBase-root .MuiSvgIcon-root{
    fill: #727272 !important;
}
.chat_head{
    padding: 11px 16px;
    background-color: #EAF4FF;
    border-radius: 8px;
    display: flex;
    gap: 8px;
}
.chat_head>img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.messages_box{
    padding: 24px 8px 24px 0;
    height: calc(100% - 106px);
    overflow: auto;
}
.main_chat{
    margin-bottom: 8px;
    width: 100%;
}
.main_chat p.para_chat{
    font-size: 14px;
    line-height: 1.2;
    font-weight: 500;
    color: #000;
    padding: 4px 12px; 
    background-color: #EAF4FF;
    border-radius: 4px;
    max-width: 70%;
    margin-bottom: 4px;
    display: inline-block;
    word-break: break-word;
}
.main_chat.left_chat p.para_chat {
    background-color: #eafff2;
}
.main_chat p.time_chat{
    font-size: 12px;
    color: #727272;
    line-height: 1;
}
.right_chat {
    text-align: right;
}
.left_chat{
    text-align: left;
}
.para_chat_imgs{
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    gap: 8px;
    margin-bottom: 8px;
}
.right_chat .para_chat_imgs {
    justify-content: flex-end;
}
.left_chat .para_chat_imgs {
    justify-content: flex-start;
}
body[dir=rtl] .right_chat .para_chat_imgs {
    justify-content: flex-start;
}
body[dir=rtl] .left_chat .para_chat_imgs {
    justify-content: flex-end;
}
.chat_input{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}
.chat_input .MuiFormControl-root{
    width: 100%;
    background-color: #EAF4FF;
    border-color: #EAF4FF;
    border-radius: 8px;
    color: #000000;
    >.MuiInputBase-root{
        height: 44px;
        >.MuiInputBase-input{
            height: 44px;
            padding: 0 8px;
        }
    }
}

.chat_input button.send_btn{
    background-color: #005DB8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    min-width: 28px;
    height: 28px;
    border-radius: 4px;
}
.chat_input button.send_btn:disabled{
    background-color: #808080a6;
}
.upload_wrap {
    width: 28px;
    height: 28px;
    display: inline-block;
    position: relative;
    cursor: pointer;
}
.upload_wrap>input{
    opacity: 0;
    width: 28px;
    height: 28px;
    cursor: pointer;
}
.upload_wrap>img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}
.upload_send_wrap {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    right: 10px; /* Default for LTR */
    top: 50%;
    transform: translateY(-50%);
}

body[dir="rtl"] .upload_send_wrap {
    left: 10px; /* Adjust for RTL */
    right: auto; /* Reset right */
}

.chat_input_inner_wrap{
    position: relative;
}
.chat_input_inner_wrap .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border: 1px solid #EAF4FF;
}
/* .chat_wrapper_parent{
    overflow: auto;
} */
.detail_btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    background-color: #005DB8;
    border: 1px solid #005DB8;
    padding: 6px 8px;
    min-width: 112px;
    height: 35px;
    border-radius: 4px;
    text-transform: capitalize;
}
.detail_btn.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover{
    background-color: #fff;
    color: #005DB8;
}
.course_card_wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
}
.course_list_wrapper{
    margin-top: 30px;
}
.date_rate_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
}
.date_rate_wrapper .css-15befwp-MuiRating-root{
    font-size: 12px;
}
.rate_review{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root.rate_session{
    background-color: #fff;
    border: 1px solid #005DB8;
    color: #005DB8;
    padding: 8px 16px;
    border-radius: 8px;
    font-size: 14px;
    border-radius: 8px;
    text-transform: capitalize;
    height: 34px;
}
.courses_video{
    min-width: 240px;
    max-width: 240px;
    height: 150px;
}

.zoom-image-modal {
    display: none;
    position: fixed;
    z-index: 9999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .zoom-image-modal-content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 700px;
  }
  
  #zoomImageModal .close {
    color: #fff;
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 35px;
    font-weight: bold;
    transition: 0.3s;
  }
  
  #zoomImageModal .close:hover,
  #zoomImageModal .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
  }
.custom-progress .MuiCircularProgress-root{
	width: 50px !important;
	height: 50px !important;
}
.custom-progress .MuiCircularProgress-root>svg{
    position: relative;
    z-index: 1;
}
.custom-progress .MuiCircularProgress-root::after{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    border: 6px solid #eaf4ff;
    border-radius: 50%;
    z-index: 0;
}