.login_wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    position: relative;
}
[dir="rtl"] .login_wrapper .left_section:has(.main_slider) .cloud_abs {
    right: unset;
    left: -100px;
    transform: scaleX(-1);
}
.left_section {
    width: 57%;
    background-color: #0f4d92;
    padding: 50px;
    position: relative;
}
.right_section {
    width: 43%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}
.cloud_abs {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: 0;
    pointer-events: none;
    height: 100%;
}
.penguine_abs {
    position: absolute;
    bottom: 0;
    right: 0;
}
.login_form {
    background-color: #fff;
    padding: 20px 16px;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    min-width: 430px;
    max-width: 430px;
}
.sel_inp_wrap > div {
    flex-direction: row-reverse;
    width: 100%;
}
.sel_inp_wrap .custom-formControl .MuiFormControl-root .MuiInputBase-root .MuiInputBase-Input {
    background-color: #f5f5f5;
    font-size: 20px;
    font-weight: 500;
    color: #727272;
    text-transform: uppercase;
    width: 100%;
}
.sel_inp_wrap .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    background-color: #f5f5f5;
    font-size: 20px;
    font-weight: 500;
    color: #727272;
    text-transform: capitalize;
    min-width: 110px;
    max-width: 110px;
}
.sel_inp_wrap .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover {
    border: none !important;
}
.sel_inp_wrap .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}
.or_box {
    margin: 8px 0 0;
    text-align: center;
    position: relative;
}
.or_box::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: 1px;
    background-color: #727272;
    pointer-events: none;
}
.or_box .css-1kfp1p5-MuiTypography-root {
    color: #727272;
    font-size: 14px;
    background-color: #fff;
    margin: 0 16px;
    position: relative;
    display: inline-flex;
    justify-content: center;
    width: 35px;
}
.btn_flex {
    display: flex;
    align-items: center;
    gap: 16px;
}
.btn_flex.MuiBox-root {
    display: flex;
    justify-content: space-between;
}
button.MuiButtonBase-root.MuiButton-root.linking_btn {
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    width: 100%;
}
.scan_box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    border: 1px solid #d3d3d3;
    padding: 8px;
    width: 100%;
    height: 46px;
    border-radius: 8px;
    text-transform: capitalize;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root.otp_btn {
    /* background-color: #F5F5F5; */
    /* color: #D3D3D3; */
    background: #0f4d92;
    color: #fff;
    padding: 10px;
    font-size: 20px;
    text-transform: capitalize;
    width: 100%;
    margin-top: 32px;
    min-height: 46px;
    border: 1px solid transparent;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root.otp_btn:disabled {
    background-color: #f5f5f5;
    color: #d3d3d3;
}
/* .css-1e6y48t-MuiButtonBase-root-MuiButton-root.otp_btn:hover {
    background-color: #FFF;
    color: #0F4D92;
    border: 1px solid #0F4D92;
} */
.main_slider {
    max-width: 545px;
    margin: 20px 20px 20px 100px;
    height: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.sliderBox_content {
    max-width: 340px;
    margin: 0 auto !important;
    display: block;
}
.swiper-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}
/* .swiper-wrapper{
    padding-bottom: 50px;
} */
.swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #ea4435;
    width: 52px;
    height: 12px;
    border-radius: 30px;
}
.sel_inp_wrap .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}
/* .sel_inp_wrap .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon, .sel_inp_wrap .css-bpeome-MuiSvgIcon-root-MuiSelect-icon{
    display: none !important;
} */
.sel_inp_wrap .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root > svg {
    display: none !important;
}
.sel_inp_wrap .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    background: url("../../assets/images/angle_down.svg") no-repeat right;
    background-size: 12px;
    margin-right: 10px;
}
[dir="rtl"] .sel_inp_wrap .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    margin-right: 0;
    margin-left: 10px;
    background-position: left;
    padding-right: 14px !important;
    padding-left: 32px !important;
}
.lang_select {
    height: auto;
    position: absolute;
    top: 36px;
    right: 50px;
    min-width: 150px;
}
.lang_select .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
    border-radius: 8px;
}
.lang_select .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #f5f5f5;
}
.lang_select .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
.lang_select .css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
    display: none;
}
.cloud_abs2 {
    display: none;
}
.sliderBox h4 {
    text-transform: capitalize;
    font-size: 24px;
}

[dir="rtl"] .penguine_abs {
    right: unset;
    left: 0;
    transform: scaleX(-1);
}
[dir="rtl"] .cloud_abs:has(.main_slider) {
    right: unset;
    left: 0;
    transform: scaleX(-1);
}
.sel_inp_wrap .custom-formControl .MuiTextField-root {
    width: 100%;
}
.sel_inp_wrap .custom-formControl > .MuiFormControl-root.MuiTextField-root > .MuiInputBase-root .MuiInputBase-input {
    background-color: #f5f5f5;
    font-size: 16px;
    font-weight: 500;
    color: #727272;
    text-transform: uppercase;
    width: 100%;
    padding: 14px;
}
.sel_inp_wrap .custom-formControl > .MuiInputBase-root > .MuiSvgIcon-root {
    display: none !important;
}
.sel_inp_wrap .custom-formControl .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}
.sel_inp_wrap .custom-formControl > .MuiInputBase-root {
    background-color: #f5f5f5;
    font-size: 16px;
    font-weight: 500;
    color: #727272;
    text-transform: capitalize;
    min-width: 110px;
    max-width: 110px;
}
.sel_inp_wrap .custom-formControl > .MuiInputBase-root > .MuiSelect-select {
    padding-right: 32px;
    background: url("../../assets/images/angle_down.svg") no-repeat right;
    background-size: 12px;
    margin-right: 10px;
    padding: 14px
}
.sel_inp_wrap .custom-formControl > {
    display: none !important;
}
body[dir="rtl"] .sel_inp_wrap .MuiFormHelperText-root {
    text-align: right;
}
.login_form .otp_btn {
    background: #0f4d92;
    color: #fff;
    padding: 8px;
    font-size: 16px;
    text-transform: capitalize;
    width: 100%;
    margin-top: 40px;
    min-height: 46px;
    border: 1px solid transparent;
}
.login_form .otp_btn:disabled {
    background-color: #f5f5f5;
    color: #d3d3d3;
}

.flagdrop > .MuiButtonBase-root {
    position: relative;
    border: 1px solid #f5f5f5;
    min-width: 150px;
    min-height: 58px;
    justify-content: flex-start;
    padding: 12px 10px;
    font-size: 20px;
    font-weight: 600;
    color: #555555;
    text-transform: capitalize;
    background-color: #ffffff !important;
}

.flagdrop > .MuiButtonBase-root > svg.MuiSvgIcon-root {
    display: none;
}
.flagdrop > .MuiButtonBase-root::after {
    content: "";
    position: absolute;
    top: 40%;
    right: 10px;
    transform: translate(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    border-right: 1px solid #727272;
    border-bottom: 1px solid #727272;
}
.flagdrop {
    position: absolute;
    top: 36px;
    right: 15px;
    z-index: 1;
}
body[dir="rtl"] .flagdrop {
    left: 50px;
    right: auto;
}
body[dir="ltr"] .flagdrop {
    left: auto;
    right: 50px;
}

.flagdrop > .MuiButtonBase-root.MuiButton-root {
    border: 1px solid #f5f5f5;
    min-width: 150px;
    min-height: 58px;
    justify-content: flex-start;
    position: relative;
    padding: 12px 10px;
    font-size: 20px;
    font-weight: 600;
    color: #555;
    text-transform: capitalize;
    background-color: #fff !important;
}
.flagdrop > .MuiButtonBase-root.MuiButton-root #flag-icons-us {
    position: absolute;
    right: 32px;
}
[dir="rtl"] .flagdrop > .MuiButtonBase-root.MuiButton-root #flag-icons-us {
    right: unset;
    left: 32px;
}
[dir="rtl"] {
    right: unset;
    left: auto;
}
[dir="rtl"] .flagdrop > .MuiButtonBase-root.MuiButton-root::after {
    right: unset;
    left: 14px;
}
[dir="rtl"] .flagdrop > .MuiButtonBase-root.MuiButton-root {
    justify-content: flex-end;
    padding-left: 30px;
}
[dir="rtl"] .flagdrop > .MuiButtonBase-root.MuiButton-root #flag-icons-kw {
    margin-right: 10px;
}
.flagdrop > .MuiButtonBase-root.MuiButton-root > svg.MuiSvgIcon-root {
    display: none;
}
.flagdrop > .MuiButtonBase-root.MuiButton-root {
    position: relative;
}
.flagdrop > .MuiButtonBase-root.MuiButton-root::after {
    content: "";
    position: absolute;
    top: 40%;
    right: 10px;
    transform: translate(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    border-right: 1px solid #727272;
    border-bottom: 1px solid #727272;
}

.flagdrop > .MuiButtonBase-root.MuiButton-root > svg.MuiSvgIcon-root {
    display: none !important;
}

.flagdrop > .MuiButtonBase-root.MuiButton-root > .MuiSelect-select {
    padding-right: 32px;
    background: url("../../assets/images/angle_down.svg") no-repeat right;
    background-size: 12px;
    margin-right: 10px;
}

.flagdrop > .MuiButtonBase-root.MuiButton-root {
    position: relative;
}
.flagdrop > .MuiButtonBase-root.MuiButton-root::after {
    content: "";
    position: absolute;
    top: 40%;
    right: 10px;
    transform: translate(-50%) rotate(45deg);
    width: 8px;
    height: 8px;
    border-right: 1px solid #727272;
    border-bottom: 1px solid #727272;
}
#simple-menu .MuiPaper-root.MuiPaper-elevation .MuiList-root .MuiButtonBase-root.MuiMenuItem-root {
    gap: 10px;
}

.otp_inputs > div {
    width: 100%;
    justify-content: space-between;
}

.otp_inputs > div {
    width: 100%;
    justify-content: space-between;
}

.otp_box {
    margin-top: 32px;
}
.otp_inputs {
    display: flex;
    align-items: center;
    gap: 16px;
}
.otp_recieve {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 32px;
}
.otp_inputs > div > div > input {
    background-color: #f5f5f5;
    border: none;
    text-align: center;
    color: #555555;
    font-size: 20px;
    font-weight: 600;
    border: 1px solid #f5f5f5;
}
.MuiButtonBase-root.otp_btn {
    /* margin-top: 16px; */
    &:hover {
        background-color: #fff;
        color: #0f4d92;
        border: 1px solid #0f4d92;
    }
}
.sms_flex {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
}
.sms_flex .sms_btn {
    background-color: #fff;
    border: 1px solid #f5f5f5;
    min-width: 178px;
    height: 40px;
    padding: 10px;
    border-radius: 30px;
    color: #000;
    display: flex;
    align-items: center;
    gap: 8px;
}
.sms_flex .sms_btn:hover,
.sms_flex .sms_btn.sms_btn_selected {
    background-color: #0f4d92;
    border: 1px solid #0f4d92;
    color: #fff;
}
.sms_flex .sms_btn:hover > img,
.sms_flex .sms_btn.sms_btn_selected > img {
    filter: invert(1);
}
.sms_flex .sms_btn:hover > p {
    color: #fff;
}
.sliderBox {
    margin: 50px 0 20px;
}

.swiper_login .swiper-pagination {
    position: relative;
}
.swiper-slide > img {
    max-width: 400px;
    margin: 0 auto;
}

@media (max-width: 1600px) {
    .main_slider {
        margin: 0;
    }
}

@media screen and (max-width: 1499px) {
    .sliderBox {
        max-width: 420px;
        margin: 30px auto 20px;
    }
    .login_form {
        min-width: 450px;
        max-width: 450px;
    }
}
@media (max-width: 1499px) {
    .main_slider .swiper-slide {
        margin: 0;
    }
    .left_section {
        padding: 15px;
        width: 54%;
    }
    .right_section {
        width: 46%;
    }
    .cloud_abs {
        right: -100px;
    }
    .swiper-slide > img {
        max-width: 360px;
        margin: 0 auto;
    }
}

@media (max-width: 1024px) {
    .left_section {
        padding: 20px;
    }
    .login_wrapper {
        flex-direction: column;
        height: 100%;
    }
    .left_section {
        width: 100%;
    }
    .cloud_abs {
        display: none;
    }
    .main_slider {
        max-width: 100%;
    }
    .main_slider > img {
        margin: 0 auto;
        max-width: 400px;
    }
    .right_section {
        width: 100%;
        margin: 40px 0 20px;
        padding: 0 15px;
        position: relative;
        z-index: 1;
    }
    .swiper-wrapper {
        padding-top: 50px;
    }
    .login_form {
        min-width: 100%;
        max-width: 100%;
    }
    .penguine_abs {
        position: static;
    }
    .flagdrop {
        top: 15px;
    }
    body[dir="ltr"] .flagdrop {
        right: 15px;
    }
    body[dir="rtl"] .flagdrop {
        left: 15px;
    }
    .main_slider .swiper-slide > img {
        margin: 0 auto;
    }
    .cloud_abs2 {
        display: block;
        position: absolute;
        top: auto;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        pointer-events: none;
    }
    .swiper-wrapper {
        padding-bottom: 160px;
    }
    .cloud_abs2 {
        bottom: -47px;
    }
}

@media (max-width: 576px) {
    .main_slider .swiper-slide {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
    }
    .main_slider .swiper-slide > img {
        max-width: 120px;
    }
    .swiper-slide .sliderBox {
        text-align: left;
    }
    .sliderBox .css-1ocdacg-MuiTypography-root {
        font-size: 16px;
    }
    .sliderBox span {
        font-size: 14px;
    }
    .left_section {
        min-height: 460px;
    }
    .main_slider .swiper-wrapper {
        padding: 0 0 120px;
    }
    .main_slider {
        padding-top: 80px;
    }
}
