.mul-dev{
    margin: 0 auto;
    /* background-color: #eaf4ff; */
    min-height: 100vh;
    height: 100vh;
    position: relative;
    .footer-cloud {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 300px;
    }
}
.mul-dev-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    height: 100%;
}

h2.blue-text {
    font-size: 30px;
    font-weight: 700;
    color: #005DB8;
}
h2.black-text {
    font-size: 30px;
    font-weight: 700;
    color: #000;
    margin-bottom: 0px;
}
.mul-dev-col p{
    width: 80%;
    align-self: center;
    font-size: 18px;
    margin-bottom: 36px;
    text-align: center;
    color: #6a6a6a;
}
button.mul-btn {
    background: #005DB8;
    border: 1px solid transparent;
    color: #fff;
    padding: 10px 18px;
    outline: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    border-radius: 4px;
    transition: 0.3s all;
}
button.mul-btn:hover{
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #005DB8;
    color: #005DB8;
}
.mul-dev-col button.MuiButtonBase-root.MuiButton-root:disabled{
	background: #808080a6;
	color: #ffffff;
    border: 1px solid transparent;
}